<script setup>
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import { computed, ref } from "vue";

const props = defineProps({
  checkinFrom: {
    type: String,
    required: true,
  },
  checkinTo: {
    type: String,
    required: true,
  },
  checkout: {
    type: String,
    required: true,
  },
  nameField: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["nextStep"]);
const checkinTimeFrom = ref(props.checkinFrom);
const checkinTimeTo = ref(props.checkinTo);
const checkoutTime = ref(props.checkout);

const isValid = computed(() => {
  return checkinTimeFrom.value && checkinTimeTo.value && checkoutTime.value;
});

function nextStep() {
  emit(
    "nextStep",
    {
      checkinFrom: checkinTimeFrom.value,
      checkinTo: checkinTimeTo.value,
      checkout: checkoutTime.value,
    },
    props.nameField
  );
}
</script>
<template>
  <div class="">
    <div class="card-header">
      <div class="row">
        <div class="col-12 text-center">
          <h4>Час заїзду і виїзду</h4>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex justify-content-center">
        <div class="w-100 max-width-300">
          <div class="h6 d-inline-flex mb-2">Заїзд</div>
          <div class="row align-items-center">
            <div class="col-6">
              <div class="input-group input-group-outline">
                <div class="form-group w-100">
                  <div class="mb-1 icon-label">з</div>
                  <input
                    v-model="checkinTimeFrom"
                    type="time"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="input-group input-group-outline">
                <div class="form-group w-100">
                  <div class="mb-1 icon-label">до</div>
                  <input
                    v-model="checkinTimeTo"
                    type="time"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="d-inline-flex mb-2 h6">Виїзд</div>
            <div class="row align-items-center">
              <div class="col-6">
                <div class="input-group input-group-outline">
                  <input
                    v-model="checkoutTime"
                    type="time"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
          <ButtonApp
            :disabled="!isValid"
            @click="nextStep"
            :class="'btn-icon bg-gradient-primary'"
          >
            Продовжити
          </ButtonApp>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</template>
