<script setup>
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
// import SidenavCollapseItem from "./SidenavCollapseItem.vue";
import allowed from "@/services/allowed";
import api from "@/services/api";
import constants from "@/constants";
import CalendarIcon from "@/assets/iconsSidebar/calendarIcon.png";
import Access from "@/assets/iconsSidebar/Access.png";
import Administrator from "@/assets/iconsSidebar/Administrator.png";
import Spa from "@/assets/iconsSidebar/Spa.png";
import Instagram from "@/assets/iconsSidebar/Instagram.png";
import Analytics from "@/assets/iconsSidebar/Analytics.png";
import Bookings from "@/assets/iconsSidebar/Bookings.png";
import ChessBookings from "@/assets/iconsSidebar/ChessBookings.png";
import ChessLimitations from "@/assets/iconsSidebar/ChessLimitations.png";
import ChessPrices from "@/assets/iconsSidebar/ChessPrices.png";
import Clients from "@/assets/iconsSidebar/Clients.png";
import Finances from "@/assets/iconsSidebar/Finances.png";
import OnlineBooking from "@/assets/iconsSidebar/OnlineBooking.png";
import Services from "@/assets/iconsSidebar/Services.png";
import Team from "@/assets/iconsSidebar/Services.png";
import SideIconSettings from "@/assets/iconsSidebar/SideIconSettings.png";
import Rooms from "@/assets/iconsSidebar/Rooms.png";
import Position from "@/assets/iconsSidebar/Position.png";
import Salary from "@/assets/iconsSidebar/Salary.png";
import telegram from "@/assets/iconsSidebar/telegram.png";
import checkUserRole from "@/services/checkUserRole";
import Multiselect from "vue-multiselect/src/Multiselect.vue";
import SelectApp from "@/components/common/SelectApp.vue";
import { onBeforeMount, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const route = useRoute();

const store = useStore();

const props = defineProps({
  cardBg: {
    type: String,
  },
});

const isSwitchingInProgress = ref(false);

const showDevFeatures = ref(false);
const company = ref({
  crmMode: null,
  localizationMode: null,
});

const currentTenant = ref("");
const multiTenants = ref(false);
const availableTenants = ref([]);

const title = ref("Soft UI Dashboard PRO");
const controls = ref("dashboardsExamples");
const isActive = ref("active");

onMounted(async () => {
  showDevFeatures.value = !!localStorage.getItem("showDevFeatures");

  company.value = await api.company.show();

  const result = await api.myprofile.getAvailableTenants();
  currentTenant.value = result.currentTenant;
  multiTenants.value = result.multiTenants;
  availableTenants.value = result.availableTenants;
});

function isOwner() {
  return checkUserRole("owner") || checkUserRole("root");
}
function getRoute() {
  const routeArr = route.path.split("/");
  return routeArr[1];
}
function getFullRouteAsArray() {
  return route.path.split("/");
}
function getFullRouteAsString() {
  return route.path;
}
async function switchTenant(tenantName) {
  if (isSwitchingInProgress.value) {
    return;
  }
  isSwitchingInProgress.value = true;

  try {
    const result = await api.myprofile.switchTenant({
      tenantName,
    });

    if (result?.accessToken) {
      store.commit("addToast", {
        title: `Перехід до "${tenantName}"...`,
      });

      localStorage.setItem("switch-accessToken", result.accessToken);

      await router.push("/authentication/logout");
      isSwitchingInProgress.value = false;
    }
  } catch (error) {
    store.commit("addToast", {
      title: "Виникла помилка. Спробуйте ще раз",
      type: "error",
    });
    isSwitchingInProgress.value = false;
  }
}

function getLabelStaffFull() {
  const localizationMode = company.value.localizationMode;

  switch (true) {
    case localizationMode === "spa":
      return "Ресурси";
    case localizationMode === "hotel":
      return "Номери";
    default:
      return "Майстри";
  }
}
</script>
<template>
  <div
    id="sidenav-collapse-main"
    class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100"
  >
    <ul class="navbar-nav">
      <li
        v-if="multiTenants && availableTenants.length > 1"
        :class="getRoute() === 'dashboards' ? 'active' : ''"
        class="nav-item"
      >
        <div class="px-3">
          <SelectApp
            v-model="currentTenant"
            :options="availableTenants"
            :settings="{
              allowEmpty: false,
              maxHeight: 270,
              searchable: true,
              placeholder: 'Select tenant',
            }"
            class="select-tenant"
            @select="switchTenant"
          >
            <template #singleLabel>
              <div class="d-flex align-items-center gap-2">
                <i class="material-icons-round collapse-icon">home</i>
                <span class="text-truncate">
                  {{ currentTenant }}
                </span>
              </div></template
            >
          </SelectApp>
        </div>
      </li>
      <hr
        v-if="multiTenants && availableTenants.length > 1"
        class="horizontal light mt-2 mb-2"
      />

      <li
        v-if="allowed('/ecommerce/calendar')"
        :class="
          getFullRouteAsString() === '/ecommerce/calendar' ? 'active' : ''
        "
        class="nav-item"
      >
        <ul v-if="company.crmMode !== 'hotel'" class="nav pe-0">
          <!-- nav links -->
          <sidenav-item
            :class="
              getFullRouteAsString() === '/ecommerce/calendar' ? 'active' : ''
            "
            svg-icon="calendar_today"
            text="Календар"
            to="/ecommerce/calendar"
          />
        </ul>
      </li>
      <li
        v-if="company.crmMode === 'hotel'"
        :class="getRoute() === 'ecommerce' ? 'active' : ''"
        class="nav-item"
      >
        <sidenav-collapse
          :class="getRoute() === 'ecommerce' ? 'active' : ''"
          collapseRef="chessTable"
          navText="Шахматки"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10">calendar_today</i>
          </template>
          <template v-slot:list>
            <ul class="nav pe-0">
              <sidenav-item
                :nested="true"
                svg-icon="event_available"
                text="Бронювання"
                to="/ecommerce/calendar"
              />
              <sidenav-item
                :nested="true"
                svg-icon="sell"
                text="Ціни"
                to="/ecommerce/rooms-prices"
              />
              <sidenav-item
                :nested="true"
                svg-icon="free_cancellation"
                text="Обмеження"
                to="/ecommerce/restrictions"
              >
              </sidenav-item>
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li
        v-if="allowed('/service-orders') && company.crmMode !== 'hotel'"
        class="nav-item"
      >
        <ul class="nav pe-0">
          <sidenav-item
            :class="
              getFullRouteAsString() === '/service-orders' ? 'active' : ''
            "
            svg-icon="playlist_add_check"
            text="Записи"
            to="/service-orders"
          />
        </ul>
      </li>
      <li
        v-if="allowed('/service-orders') && company.crmMode === 'hotel'"
        class="nav-item"
      >
        <ul class="nav pe-0">
          <sidenav-item
            :class="
              getFullRouteAsString() === '/service-orders' ? 'active' : ''
            "
            svg-icon="playlist_add_check"
            text="Броні"
            to="/service-orders"
          />
        </ul>
      </li>
      <li v-if="allowed('/clients')" class="nav-item">
        <ul class="nav pe-0">
          <sidenav-item
            :class="getFullRouteAsString() === '/clients' ? 'active' : ''"
            svg-icon="diversity_1"
            text="Клієнти"
            to="/clients"
          />
        </ul>
      </li>
      <li
        v-if="isOwner()"
        :class="getRoute() === 'analytics' ? 'active' : ''"
        class="nav-item"
      >
        <sidenav-collapse
          :class="getRoute() === 'analytics' ? 'active' : ''"
          collapseRef="analyticsTree"
          navText="Аналітика"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10">analytics</i>
          </template>
          <template v-slot:list>
            <ul class="nav pe-0">
              <sidenav-item
                :class="getFullRouteAsString() === '/analytics' ? 'active' : ''"
                :nested="true"
                svg-icon="analytics"
                text="Загальні показники"
                to="/analytics"
              />
              <sidenav-item
                v-if="company.crmMode === 'hotel'"
                :nested="true"
                svg-icon="analytics"
                text="Завантаженість"
                to="/analytics/bystaff"
              />
              <sidenav-item
                v-if="company.crmMode !== 'hotel'"
                :nested="true"
                svg-icon="analytics"
                text="За майстрами"
                to="/analytics/bystaff"
              />
              <sidenav-item
                v-if="company.crmMode === 'hotel'"
                :nested="true"
                svg-icon="analytics"
                text="За бронюваннями"
                to="/analytics/byserviceorders"
              />
              <sidenav-item
                v-if="company.crmMode !== 'hotel'"
                :nested="true"
                svg-icon="analytics"
                text="За записами"
                to="/analytics/byserviceorders"
              />
              <sidenav-item
                :nested="true"
                svg-icon="analytics"
                text="За дод. послугами"
                to="/analytics/byadditionalservices"
              />
              <sidenav-item
                v-if="showDevFeatures"
                :nested="true"
                svg-icon="analytics"
                text="DEV За послугами"
                to="/analytics/byservices"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li
        class="nav-item"
        :class="getRoute() === 'reviews' ? 'active' : ''"
        v-if="allowed('/reviews')"
      >
        <sidenav-collapse
          collapseRef="analyticsTree"
          navText="Відгуки"
          :class="getRoute() === 'reviews' ? 'active' : ''"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10">reviews</i>
          </template>
          <template v-slot:list>
            <ul class="nav pe-0">
              <sidenav-item
                to="/reviews/reviews-overview"
                :nested="true"
                text="Відгуки клієнтів"
                :class="getFullRouteAsString() === '/reviews' ? 'active' : ''"
              />
              <sidenav-item
                :nested="true"
                to="/reviews/reviews-settings"
                svg-icon="reviews"
                text="Налаштування"
                :class="getFullRouteAsString() === '/reviews' ? 'active' : ''"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <!-- <li v-if="showDevFeatures && allowed('/pages/journal')" class="nav-item">
        <ul class="nav pe-0">
          <sidenav-item
            svg-icon="subject"
            text="Журнал DEV"
            to="/pages/journal"
          />
        </ul>
      </li> -->
      <li v-if="isOwner()" class="nav-item">
        <ul class="nav pe-0">
          <sidenav-item
            svg-icon="payments"
            text="Фінанси"
            to="/finances/cash"
          />
          <!-- TODO: вместо miniicon Ф вот эту иконку бы
          <i class="material-icons-round opacity-10">attach_money</i> -->
        </ul>
      </li>
      <li
        v-if="company.crmMode === 'hotel' && allowed('/spa')"
        class="nav-item"
      >
        <ul class="nav pe-0">
          <sidenav-item :icon="true" text="SPA модуль" to="/spa">
            <template #icon>
              <img :src="Spa" alt="" />
            </template>
          </sidenav-item>
        </ul>
      </li>
      <!-- no priority -->
      <!-- <li v-if="showDevFeatures" class="nav-item">
        <ul class="nav pe-0">
          <sidenav-item
            :class="getFullRouteAsString() === '/certificates' ? 'active' : ''"
            svg-icon="redeem"
            text="Сертифікати DEV"
            to="/certificates"
          />
        </ul>
      </li> -->
      <!-- no priority -->
      <!-- <li v-if="showDevFeatures && allowed('/abonements')" class="nav-item">
        <ul class="nav pe-0">
          <sidenav-item
            svg-icon="badge"
            text="Абонементи DEV"
            to="/abonements"
          />
        </ul>
      </li> -->

      <!-- no priority -->
      <!-- <li
        v-if="showDevFeatures && allowed('/warehouse')"
        :class="getRoute() === 'warehouse' ? 'active' : ''"
        class="nav-item"
      >
        <sidenav-collapse
          :class="getRoute() === 'warehouse' ? 'active' : ''"
          collapseRef="warehouseTree"
          navText="Склад DEV"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10">warehouse</i>
          </template>
          <template v-slot:list>
            <ul class="nav pe-0">
              <sidenav-item
                :nested="true"
                miniIcon="П"
                text="Продажі"
                to="/warehouse/product-orders"
              />
              <sidenav-item
                :nested="true"
                miniIcon="Т"
                text="Товари"
                to="/warehouse/products-list"
              />
              <sidenav-item
                :nested="true"
                miniIcon="П"
                text="Постачальники"
                to="/warehouse/suppliers"
              />
              <sidenav-item
                :nested="true"
                miniIcon="П"
                text="Поставки"
                to="/warehouse/suppliesdatatable"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li> -->
      <li v-if="allowed('/settings')" class="mt-3 nav-item">
        <h6
          :class="store.state.isRTL ? 'me-4' : 'ms-2'"
          class="text-xs ps-4 text-uppercase font-weight-bolder text-white"
        >
          Керування
        </h6>
      </li>
      <li v-if="allowed('/subscription')" class="nav-item">
        <ul class="nav pe-0">
          <sidenav-item
            v-if="showDevFeatures"
            :class="getFullRouteAsString() === '/subsctiption' ? 'active' : ''"
            svg-icon="subscriptions"
            text="DEV Підписка"
            to="/subscription"
          />
        </ul>
      </li>
      <li v-if="allowed('/subscription')" class="nav-item">
        <ul class="nav pe-0">
          <sidenav-item
            v-if="company.crmMode === 'hotel'"
            :icon="true"
            text="IG Stories maker"
            to="/ig-story-maker"
          >
            <template #icon>
              <img :src="Instagram" alt="" />
            </template>
          </sidenav-item>
        </ul>
      </li>

      <li
        v-if="allowed('/settings')"
        :class="getRoute() === 'settings' ? 'active' : ''"
        class="nav-item"
      >
        <sidenav-collapse
          :class="getRoute() === 'settings' ? 'active' : ''"
          collapseRef="settingsTree"
          navText="Налаштування"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10">settings</i>
          </template>

          <template v-slot:list>
            <ul class="nav pe-0">
              <sidenav-item
                :nested="true"
                svg-icon="apartment"
                text="Моя компанія"
                to="/settings/company"
              />
              <sidenav-item
                v-if="company.crmMode === 'hotel'"
                :nested="true"
                svg-icon="meeting_room"
                text="Номери"
                to="/settings/staff"
              />
              <sidenav-item
                v-if="company.crmMode === 'hotel'"
                :nested="true"
                svg-icon="category"
                text="Категорії номерів"
                to="/settings/positions"
              />
              <sidenav-item
                v-if="company.crmMode === 'hotel'"
                :nested="true"
                svg-icon="percent"
                text="Знижки"
                to="/settings/discounts"
              />

              <sidenav-item
                v-if="company.crmMode !== 'hotel'"
                :nested="true"
                svg-icon="computer"
                text="Онлайн запис"
                to="/settings/online-booking"
              />
              <sidenav-item
                v-if="company.crmMode === 'hotel'"
                :nested="true"
                svg-icon="computer"
                text="Онлайн бронь"
                to="/settings/online-booking"
              />
              <sidenav-item
                :nested="true"
                svg-icon="computer"
                text="Онлайн оплата"
                to="/settings/online-payment"
              />
              <sidenav-item
                v-if="company.crmMode !== 'hotel' || showDevFeatures"
                :nested="true"
                svg-icon="volunteer_activism"
                text="Послуги"
                to="/settings/services"
              />
              <sidenav-item
                :nested="true"
                svg-icon="volunteer_activism"
                text="Додаткові послуги"
                to="/settings/additional-services"
              />
              <!-- no priority -->
              <!-- <sidenav-item
                v-if="showDevFeatures"
                :nested="true"
                svg-icon="key"
                text="DEV Доступи"
                to="/settings/permissions"
              /> -->
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li
        v-if="allowed('/team')"
        :class="getRoute() === 'team' ? 'active' : ''"
        class="nav-item"
      >
        <sidenav-collapse
          :class="getRoute() === 'team' ? 'active' : ''"
          collapseRef="teamTree"
          navText="Команда"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10">people</i>
          </template>
          <template v-slot:list>
            <ul class="nav pe-0">
              <sidenav-item
                :icon="true"
                :nested="true"
                text="Адміністратори"
                to="/team/managers"
              >
                <template #icon>
                  <img :src="Administrator" alt="" />
                </template>
              </sidenav-item>
              <sidenav-item
                v-if="company.crmMode !== 'hotel'"
                :nested="true"
                :text="getLabelStaffFull()"
                svg-icon="person"
                to="/settings/staff"
              />
              <sidenav-item
                v-if="company.crmMode !== 'hotel'"
                :nested="true"
                svg-icon="edit_calendar"
                text="Графік роботи"
                to="/team/workschedule"
              />

              <sidenav-item
                v-if="company.crmMode !== 'hotel'"
                :icon="true"
                :nested="true"
                text="Посади"
                to="/settings/positions"
              >
                <template #icon>
                  <img :src="Position" alt="" />
                </template>
              </sidenav-item>

              <!-- no priority -->
              <!-- <sidenav-item
                v-if="showDevFeatures"
                :icon="true"
                :nested="true"
                text="Зарплатня DEV"
                to="/team/salary"
              >
                <template #icon>
                  <img :src="Salary" alt="" />
                </template>
              </sidenav-item> -->
            </ul>
          </template>
        </sidenav-collapse>
      </li>
      <li v-if="allowed('/channel-manager')" class="nav-item">
        <ul class="nav pe-0">
          <sidenav-item
            v-if="company.crmMode === 'hotel'"
            svg-icon="share"
            text="Менеджер каналів"
            to="/channel-manager"
          />
        </ul>
      </li>

      <li
        v-if="allowed('/integrations')"
        :class="getRoute() === 'integrations' ? 'active' : ''"
        class="nav-item"
      >
        <sidenav-collapse
          :class="getRoute() === 'integrations' ? 'active' : ''"
          collapseRef="settingsTree"
          navText="Інтеграції"
        >
          <template v-slot:icon>
            <i class="material-icons-round opacity-10"
              >integration_instructions</i
            >
          </template>
          <template v-slot:list>
            <ul class="nav pe-0">
              <!-- <sidenav-item
                :nested="true"
                miniIcon="m"
                text="DEV plata by mono"
                to="/integrations/monobank"
                v-if="showDevFeatures"
              /> -->
              <sidenav-item
                :nested="true"
                miniIcon="L"
                text="SA LiqPay"
                to="/integrations/liqpay"
                v-if="showDevFeatures"
              />

              <sidenav-item
                :icon="true"
                :nested="true"
                text="Telegram"
                to="/integrations/telegram"
              >
                <template #icon>
                  <img :src="telegram" alt="" />
                </template>
              </sidenav-item>
              <sidenav-item
                :nested="true"
                svg-icon="sms"
                text="SMS"
                to="/integrations/sms"
              />
              <sidenav-item
                :nested="true"
                svg-icon="widgets"
                text="Віджет на сайт"
                to="/integrations/widget"
              />
              <sidenav-item
                :nested="true"
                svg-icon="code"
                text="API"
                to="/integrations/api"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li
        v-if="allowed('/admin-area') && showDevFeatures"
        :class="getRoute() === 'admin-area' ? 'active' : ''"
        class="nav-item"
        style="color: #f44335 !important"
      >
        <sidenav-collapse
          :class="getRoute() === 'maintenance' ? 'active' : ''"
          class="text-danger"
          collapseRef="settingsTree"
          navText="Admin area"
          style="color: #f44335 !important"
        >
          <template v-slot:icon>
            <i
              class="material-icons-round opacity-10"
              style="color: #f44335 !important"
              >lock</i
            >
          </template>
          <template v-slot:list>
            <ul class="nav pe-0">
              <sidenav-item
                miniIcon="M"
                text="migrations"
                to="/maintenance/migrations"
              />
              <sidenav-item miniIcon="C" text="cron" to="/maintenance/cron" />
              <sidenav-item
                miniIcon="T"
                text="tenants"
                to="/maintenance/tenants"
              />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>
<style scoped>
:deep(.select-tenant .multiselect__tags) {
  padding-right: 34px;
  padding-left: 16px;
  background: transparent;
  border: 0.5px solid transparent;
  min-height: 50px;
}
:deep(.select-tenant .multiselect__single) {
  background: transparent;
  color: #fff;
}
:deep(.select-tenant .multiselect__content-wrapper) {
}
</style>
