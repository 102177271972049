<script setup>
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import { useRouteQuery } from "vue-use-route-query";
import InputApp from "@/components/common/inputs/InputApp.vue";
import { computed, onMounted } from "vue";
import { useFetch } from "@/composables/common/api/useFetch";
import api from "@/services/api";
import { useRouter } from "vue-router";

const router = useRouter();
const name = useRouteQuery("namePosition", "");

const validName = computed(() => {
  return name.value.length > 2;
});

const { data: positionList, fetchData: getPositionList } = useFetch(
  api.positions.list
);

const positionId = computed(() => {
  if (positionList.value?.length) {
    return positionList.value[0]._id;
  }
  return null;
});
const staffTemplate = {
  isActive: true,
  name: "",
  phoneNumber: "",
  positionId: null,
  photo: {
    key: "",
    url: "",
  },
  photos: [],
  onlineBooking: true,
  description: "",

  checkinRules: {
    guestsMax: 2,
    checkinTime: {
      from: "15:00",
      to: "21:00",
    },
    checkoutTime: "12:00",
    smartCheckin: {
      enabled: false,
      iconName: "CheckInIcon",
      text: "",
      description: "",
    },
    petsFriendly: {
      enabled: false,
      iconName: "PetsIcon",
      text: "",
      description: "",
    },
    children: {
      enabled: false,
      iconName: "KidsIcon",
      text: "",
      description: "",
    },
  },

  cancellationPolicy: {
    enabled: false,
    rules: [
      {
        enabled: true,
        rule: "greaterThen",
        days: 10,
        returnPercentage: 100,
      },
      {
        enabled: true,
        rule: "lessThen",
        days: 10,
        returnPercentage: 50,
      },
      {
        enabled: true,
        rule: "lessThen",
        days: 3,
        returnPercentage: 0,
      },
    ],
  },
  isRemoved: false,
};

const {
  data,
  isLoading,
  fetchData: createPosition,
} = useFetch(api.staff.create, true);
async function submitForm() {
  try {
    const result = await createPosition([
      {
        ...staffTemplate,
        name: name.value,
        positionId: positionId.value,
      },
    ]);
    if (result) {
      router.push("/beauty/onboarding/add-service");
    }
  } catch (e) {
    console.error(e);
  }
}
onMounted(async () => {
  await getPositionList();
});
</script>
<template>
  <div class="card mt-6">
    <div class="card-header">
      <div class="row">
        <div class="col-12 text-center">
          <h4>Створення співробітника</h4>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="submitForm" action="#">
        <div class="row text-center">
          <p>Вкажіть імʼя співробітника, який буде виконкувати послуги</p>
        </div>
        <div class="d-flex justify-content-center">
          <div class="max-width-300 w-100">
            <InputApp
              placeholder="Ім`я співробітника"
              class="w-100"
              v-model="name"
            ></InputApp>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <ButtonApp
            type="submit"
            :loading="isLoading"
            :disabled="!validName"
            class="btn-icon bg-gradient-primary"
          >
            Створити
          </ButtonApp>
        </div>
      </form>
    </div>
  </div>
</template>
