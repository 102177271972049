<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { Scheduler } from "@dhx/scheduler";
import { DateTime } from "luxon";
import { sizes } from "@/utils/constants/common";
import { useBreakpoints } from "@/composables/common/useBreakpoint";
import {
  TYPE_CONSTRUCTOR_PRICE,
  TYPE_CONSTRUCTOR_RULES,
} from "@/utils/enums/typeConstructor";
import { useScheduler } from "@/composables/useScheduler";
import { checkElementIsInArray } from "@/utils/array";
import * as _ from "lodash";
const { schedulerWrapRef, setHeightScheduler } = useScheduler();

const props = defineProps({
  items: { type: [Array, null], required: true },
  events: { type: Array, required: true },
  date: { type: String, default: DateTime.now().toISODate() },
  smallerView: { type: Boolean, default: false },
  countMonthView: { type: Number, default: 1 },
  typeConstructor: {
    type: String,
    required: true,
  },
  isLoading: { type: Boolean, default: false },
});
const emit = defineEmits(["updateDate", "updateEvent"]);

defineExpose({
  updateScheduler,
});

const categoriesIds = computed(() => {
  let result = new Set([]);
  props.items?.forEach((item) => {
    result.add(item.positionId._id);
  });

  return Array.from(result);
});

const itemsWithCategories = computed(() => {
  let resultArr = [];

  props.items?.forEach((item) => {
    let categoryIndex = resultArr.findIndex(
      (category) => category.key === item.positionId._id
    );
    if (categoryIndex >= 0) {
      resultArr[categoryIndex].children.push(item);
    } else {
      const key = item.positionId._id;
      const label = item.positionId.name;
      const rank = item.positionId.rank;
      const itemWithCategory = {
        key,
        label,
        rank,
        open: true,
        children: [item],
      };
      resultArr.push(itemWithCategory);
    }
  });

  resultArr = _.sortBy(resultArr, "rank");

  return resultArr;
});
watch(itemsWithCategories, (val) => {
  if (val) {
    scheduler.value.updateCollection("items", val);
  }
});

watch(
  () => props.smallerView,
  (val) => {
    if (val) {
      scheduler.value.getView("timeline").column_width = widthColumn.value;
      updateScheduler();
    }
  }
);
watch(
  () => props.countMonthView,
  (val) => {
    if (val) {
      scheduler.value.getView("timeline").x_size = getCountDays(
        date.value,
        val
      );

      updateScheduler();
    }
  }
);

const date = computed(() => {
  return DateTime.fromISO(props.date).toJSDate();
});
watch(date, (val) => {
  if (val) {
    scheduler.value.getView("timeline").x_size = getCountDays(
      val,
      props.countMonthView
    );
  }
});
watch(
  () => props.events,
  (val) => {
    if (val) {
      updateScheduler();
    }
  }
);

const schedulerRef = ref(null);
const scheduler = ref(null);

const { isMobile } = useBreakpoints();
const widthColumn = computed(() => {
  if (isMobile.value && props.smallerView) {
    return sizes.WIDTH_COLUMN_SCHEDULER_SMALLER_MOB;
  }
  if (isMobile.value) {
    return sizes.WIDTH_COLUMN_SCHEDULER_MOB;
  }

  return sizes.WIDTH_COLUMN_SCHEDULER_PC;
});
const widthAreas = computed(() => {
  if (isMobile.value && props.smallerView) {
    return sizes.WIDTH_AREA_SCHEDULER_SMALLER_MOB;
  }
  if (isMobile.value) {
    return sizes.WIDTH_AREA_SCHEDULER_MOB;
  }
  return sizes.WIDTH_AREA_SCHEDULER_PC;
});

onMounted(() => {
  schedulerRef.value.style.setProperty(
    "--width-cell",
    (isMobile.value
      ? sizes.WIDTH_COLUMN_SCHEDULER_MOB
      : sizes.WIDTH_COLUMN_SCHEDULER_PC) + "px"
  );
  initScheduler();
  setTimeout(() => {
    setHeightScheduler(schedulerWrapRef.value);
  }, 500);
});
onBeforeUnmount(() => {
  scheduler.value.deleteMarkedTimespan({
    days: [0, 6],
  });
  scheduler.value.destructor();
  scheduler.value = null;
});
function initScheduler() {
  scheduler.value = Scheduler.getSchedulerInstance();
  scheduler.value.setSkin("material");
  scheduler.value.config.show_loading = true;

  scheduler.value.config.undo_deleted = false;

  scheduler.value.config.header = ["date", "today", "prev", "next"];
  scheduler.value.config.container_autoresize = true;
  scheduler.value.i18n.setLocale("ua");
  scheduler.value.plugins({
    treetimeline: true,
    timeline: true,
    limit: true,
    container_autoresize: true,
    outerdrag: true,
    html_templates: true,
  });
  scheduler.value.config.drag_resize = false;
  scheduler.value.config.drag_move = false;
  scheduler.value.config.drag_create = false;
  scheduler.value.config.edit_on_create = false;
  scheduler.value.config.dblclick_create = false;
  scheduler.value.config.mark_now = true;
  scheduler.value.config.drag_highlight = true; // TODO: maybe redundant

  scheduler.value.createTimelineView({
    name: "timeline",
    x_unit: "day",
    x_date: "%D, %d",
    x_size: getCountDays(date.value, props.countMonthView),
    x_start: 0,
    dx: widthAreas.value,
    event_min_dy: 60,
    event_dy: "full",
    date: "%D, %d",
    scrollable: true,
    column_width: widthColumn.value,
    y_unit: scheduler.value.serverList("items", itemsWithCategories.value),
    y_property: "item_id",
    render: "tree",
    second_scale: {
      x_unit: "month",
      x_date: "%F",
    },
  });
  scheduler.value.init(
    schedulerRef.value,
    DateTime.fromJSDate(date.value).startOf("month").toJSDate(),
    "timeline"
  );
  scheduler.value.locale.labels.timeline_scale_header = "Номери";
  scheduler.value.parse(props.events);
  scheduler.value.addMarkedTimespan({
    days: [0, 6],
    zones: "fullday",
    css: "scheduler_weekends",
  });
  scheduler.value.templates.timeline_scale_label = function (
    key,
    label,
    section
  ) {
    return `<div class="dhx_matrix_scell-name">${label}</div>`;
  };

  scheduler.value.attachEvent(
    "onBeforeViewChange",
    function (old_mode, old_date, mode, date) {
      if (props.countMonthView === 1 && date) {
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        date.setDate(1);
        if (old_date) {
          let was = old_date.getMonth();
          let now = date.getMonth();
          if (was - now > 1) {
            if (DateTime.fromJSDate(date).month !== DateTime.now().month) {
              date.setMonth(date.getMonth() + 1);
            } else {
              date.setMonth(date.getMonth());
            }
            month = date.getMonth() + 1;
          }
        }
        let d = new Date(year, month, 0);
        let days = d.getDate();

        if (days) {
          scheduler.value.matrix["timeline"].x_size = days;
          scheduler.value.matrix["timeline"].x_length = days;
        }
      }

      emit("updateDate", date);

      return true;
    }
  );
  scheduler.value.date.timeline_start = scheduler.value.date.month_start;
  scheduler.value.templates.timeline_scale_date = function (date) {
    return `
      ${DateTime.fromJSDate(date).setLocale("ua").toFormat("ccc, dd")}
     `;
  };
  scheduler.value.templates.timeline_date = function (date1, date2) {
    return `
      ${DateTime.fromJSDate(date1)
        .setLocale("ua")
        .toFormat("dd MMMM")} - ${DateTime.fromJSDate(date2)
      .minus({ days: 1 })
      .setLocale("ua")
      .toFormat("dd MMMM")}
     `;
  };
  scheduler.value.templates.event_bar_text = function (start, end, event) {
    return getEventTemplate(event);
  };

  scheduler.value.attachEvent("onEmptyClick", function (date, e) {
    const id = scheduler.value.getActionData(e).section;
    const isClickOnCategory = checkElementIsInArray(categoriesIds.value, id);
    if (!isClickOnCategory) {
      emit("updateEvent", id, DateTime.fromJSDate(date).toISODate());
    }

    return true;
  });
}

function updateScheduler() {
  scheduler.value.clearAll();
  scheduler.value.parse(props.events);
  scheduler.value.setCurrentView();
}

function getEventTemplate(item) {
  let template = "";
  if (props.typeConstructor === TYPE_CONSTRUCTOR_PRICE) {
    if (item.prices?.length > 0 && item.isSomePrice) {
      template += '<div class="prices"> ';
    }
    template += item.prices
      ? item.prices
          .map((itemPrice) => {
            let priceTemplate = "";
            priceTemplate += '<div class="prices__row">';
            if (itemPrice.price) {
              priceTemplate += `${itemPrice.upToGuestsNumber}-<span>${itemPrice.price}`;
              if (itemPrice.prepaymentAmount) {
                priceTemplate += `/${itemPrice.prepaymentAmount}`;
              }
            }
            priceTemplate += "</div>";
            return priceTemplate;
          })
          .join("")
      : "";
    if (item.prices?.length > 0 && item.isSomePrice) {
      template += "</div>";
    }
  }
  if (props.typeConstructor === TYPE_CONSTRUCTOR_RULES) {
    if (item.minDays && item.minDays > 1) {
      template += '<div class="prices"> ';
      template += '<div class="prices__row">';
      template += "</div>";
      template += `<div>мін. днів: ${item.minDays}</div>`;
      template += "</div>";
    }

    if (Object.hasOwn(item, "checkInNotAllowed") && item.checkInNotAllowed) {
      template += '<div class="prices"> ';
      template += '<div class="prices__row">';
      template += "</div>";
      template += `<div>❌ заїзд</div>`;
      template += "</div>";
    }

    if (Object.hasOwn(item, "checkOutNotAllowed") && item.checkOutNotAllowed) {
      template += '<div class="prices"> ';
      template += '<div class="prices__row">';
      template += "</div>";
      template += `<div>❌ виїзд</div>`;
      template += "</div>";
    }
  }

  return template;
}

function getCountDays(dateStr, month) {
  const date1 = DateTime.fromJSDate(dateStr).startOf("month");
  const date2 = date1.plus({ month: month });
  const diff = date2.diff(date1, "days");

  return diff.toObject().days;
}
</script>

<template>
  <div ref="schedulerWrapRef" class="scheduler-wrap-constructor">
    <div
      class="scheduler-timeline scheduler"
      :class="{
        'smaller-view': props.smallerView && isMobile,
      }"
      ref="schedulerRef"
    ></div>
  </div>
</template>

<style lang="scss">
@import "~@dhx/scheduler/codebase/dhtmlxscheduler.css";
.scheduler-wrap-constructor {
  min-height: 300px;
  height: 650px;
  .dhx_matrix_scell .dhx_scell_level0 {
    padding-left: 0;
  }
  .dhx_matrix_scell.item .dhx_scell_name {
    padding-left: 0;
  }
  .dhx_scell_level1 {
    padding-left: 8px;
  }
  @media (max-width: 768px) {
    .dhx_matrix_scell.folder .dhx_scell_expand:before {
      font-size: 12px;
    }
    .dhx_matrix_scell .dhx_scell_level0 {
      padding-left: 0;
    }
    .dhx_matrix_scell.item .dhx_scell_name {
      padding-left: 2px;
    }
    .dhx_matrix_scell dhx_treetimeline {
      padding: 3px;
    }
    .dhx_matrix_scell .dhx_scell_level1 {
      padding-left: 0;
    }
    .dhx_matrix_scell-name {
      font-size: 8px;
    }
  }

  .scheduler-timeline {
    position: relative;
    &.half-transform {
      .dhx_cal_event_line {
        transform: translateX(calc((var(--width-cell, 40px) * -1) / 2));
      }
      &.smaller-view {
        .dhx_cal_event_line {
          transform: translateX(calc((var(--width-cell, 40px) * -1) / 2));
        }
      }
    }
  }

  .dhx_cal_container {
    height: 100% !important;
  }

  .dhx_marked_timespan.scheduler_weekends {
    background: rgba(255, 220, 40, 0.15);
  }

  .dhx_marked_timespan,
  .dhx_matrix_cell {
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);

    &:hover {
      background: lightcyan !important;
    }
  }

  .dhx_timeline_scale_header.dhx_timeline_second_scale {
    border-right: 1px solid #e0e0e0;
  }
  .dhx_scale_bar {
    font-size: 12px;
    transform: translateY(-4px);
    @media (max-width: 768px) {
      font-size: 8px;
    }
  }

  .scheduler-timeline {
    .material-icons {
      font-size: 12px;
    }
  }
  .dhx_second_scale_bar {
    border: 1px solid #e0e0e0 !important;
  }

  .dhx_cal_scale_placeholder {
    box-shadow: none !important;
    border-bottom: 1px solid #e0e0e0;
    z-index: 4;
    pointer-events: none;
  }

  .dhx_scale_bar {
    font-size: 12px;
    font-weight: bold;
    text-transform: capitalize;
    @media (max-width: 768px) {
      font-size: 12px;
      font-weight: 500;
    }
  }
  .dhx_cal_event_line,
  .dhx_cal_event_clear {
    font-size: 10px;
    font-weight: 400;
    line-height: 1.3;
    padding: 4px;
    white-space: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: (calc((var(--width-cell, 40px))));
    text-overflow: ellipsis;
    border: none;
    flex-direction: column;
    align-items: flex-start;
    color: #84889e;
    transition: all 0.3s cubic-bezier(0.5, 0.02, 0.13, 0.5);
    pointer-events: none;
    &:hover {
      background: lightcyan !important;
    }
    @media (max-width: 768px) {
      font-size: 8px;
      color: #84889e;
    }
    & > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    background: transparent;
  }
  .booking_status,
  .booking_paid {
    position: absolute;
    right: 5px;
  }

  .booking_status {
    top: 2px;
  }

  .booking_paid {
    bottom: 2px;
  }
  .current-order {
    box-shadow: inset 0 0 0 2px red;
  }
  .dhx_cal_navline .dhx_cal_date {
    font-size: 16px;
  }
  .dhx_matrix_scell {
    padding: 10px;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 400;

    @media (max-width: 768px) {
      font-size: 10px;
      padding: 5px;
    }
  }
  .dhx_matrix_scell-name {
    text-align: left;
    overflow: hidden;
    -webkit-line-clamp: 3;
    display: flex;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .event-top {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    &__prices {
      display: flex;
      align-items: center;
      gap: 4px;
      font-weight: 500;
    }
    &__price-rest {
      color: red;
    }
    &__price-all {
      color: green;
    }
  }
  .dhx_event_resize {
    filter: invert(0.3);
  }
  .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_date {
    text-align: left;
  }
  .smaller-view {
    @media (max-width: 768px) {
      .dhx_cal_navline.dhx_cal_navline_flex .dhx_cal_date {
        font-size: 10px;
      }
      .dhx_matrix_scell {
        padding: 2px;
        font-size: 8px;
      }
      .dhx_cal_today_button {
        font-size: 8px !important;
      }
      .dhx_cal_event_line {
        font-size: 8px;
      }
    }
  }
  .dhx_cal_date {
    text-align: left;
    margin-left: 0 !important;
  }

  .dhx_cal_navline.dhx_cal_navline_flex {
    .dhx_cal_nav_button {
      background-color: #0f4432;
      border: none;
      font-size: 0.875rem;
      order: 3;
      min-width: 35px;
      height: 35px;
      box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
        0 2px 4px -1px rgba(0, 0, 0, 0.07);
      transition: all 0.15s ease-in;
      color: #fff;
      text-transform: capitalize;
      border-radius: 5px;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      padding: 0;
      background-image: none;
      @media (max-width: 768px) {
        min-width: 28px;
        height: 28px;
      }

      &:hover {
        opacity: 0.9;
      }
    }

    .dhx_cal_prev_button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        background: url("../../assets/img/arrow-prev.svg") center / 100% 100%
          no-repeat;
        width: 16px;
        height: 16px;
      }
    }

    .dhx_cal_next_button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        background: url("../../assets/img/arrow-prev.svg") center / 100% 100%
          no-repeat;
        width: 16px;
        height: 16px;
        transform: rotate(180deg);
      }
    }

    .dhx_cal_today_button {
      padding: 5px 16px;
      margin-right: 10px;
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }

  .dhx_timeline_scale_header.dhx_timeline_second_scale {
    height: 40px !important;
  }
  .dhx_cal_navline.dhx_cal_navline_flex {
    .dhx_cal_nav_button {
      background-color: #0f4432;
      border: none;
      font-size: 0.875rem;
      min-width: 35px;
      height: 35px;
      box-shadow: 0 4px 7px -1px rgba(0, 0, 0, 0.11),
        0 2px 4px -1px rgba(0, 0, 0, 0.07);
      transition: all 0.15s ease-in;
      color: #fff;
      text-transform: capitalize;
      border-radius: 5px;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      padding: 0;
      background-image: none;
      @media (max-width: 768px) {
        min-width: 28px;
        height: 28px;
      }
      &:hover {
        opacity: 0.9;
      }
    }
    .dhx_cal_prev_button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        background: url("../../assets/img/arrow-prev.svg") center / 100% 100%
          no-repeat;
        width: 16px;
        height: 16px;
      }
    }
    .dhx_cal_next_button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        background: url("../../assets/img/arrow-prev.svg") center / 100% 100%
          no-repeat;
        width: 16px;
        height: 16px;
        transform: rotate(180deg);
      }
    }
    .dhx_cal_today_button {
      padding: 5px 16px;
      margin-right: 10px;
      @media (max-width: 768px) {
        font-size: 12px;
        padding: 5px 10px;
      }
    }
  }

  .dhx_cal_scale_placeholder {
    box-shadow: none;
    border-bottom: 1px solid #e0e0e0;
    z-index: 4;
    pointer-events: none;
  }
}
</style>
