<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>

  <div class="container-fluid" v-show="dataLoaded">
    <div class="row">
      <div class="col-lg-6 mx-auto">
        <div class="col">
          <div class="card mt-5">
            <div class="card-body p-3 pt-0">
              <div class="col-8 my-auto" v-if="showDevFeatures">
                <p class="text-muted text-sm mt-3">
                  Статус підписки<br />
                  <span class="badge bg-gradient-success badge-sm"
                    >Сплачено</span
                  >
                  <span class="badge bg-gradient-info badge-sm">trial</span>
                  <span class="badge bg-gradient-warning badge-sm"
                    >очікує оплати</span
                  >
                </p>
              </div>
              <div class="row">
                <p class="text-muted text-sm">
                  Шановний клієнте! Ваша підписка скінчилась. Щоб продовжувати
                  користуватись bookmenow, будь ласка, сплатіть абонплату. Або
                  зверніться до менеджера.
                </p>
                <p class="text-muted text-sm">
                  Вартість абонплати:
                  <strong> {{ company.forcePaymentAmount }} грн.</strong>
                </p>
                <div class="col-lg-3" v-if="company.forcePaymentUrl">
                  <a
                    :href="company.forcePaymentUrl"
                    target="_blank"
                    class="btn btn-md bg-gradient-primary mb-0"
                  >
                    Cплатити
                  </a>
                </div>
                <div class="col-lg-6">
                  <a
                    href="https://t.me/bookmenowsupport"
                    target="_blank"
                    class="btn btn-md btn-outline-primary mb-0"
                    >Написати менеджеру</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="showDevFeatures">
      <div class="col-lg-6 mt-2 mx-auto">
        <div class="mt-3">
          <div class="card">
            <span class="badge bg-gradient-info">DEV</span>
            <div class="card-body p-3 pt-0">
              <div class="row"></div>
              <div class="row">
                <div class="col text-end">
                  <button type="button" class="btn btn-sm btn-outline-danger">
                    Скасувати
                  </button>
                </div>
                <label class="text-xs text-end"
                  >Скасовуючи підписку у вас залишається доступ до системи доки
                  діє вже сплачений період.<br />
                  Ми зберігаємо вашу інформацію впродовж 6 місяців в разі якщо
                  ви вирішете до нас повернутись. Через 6 місяців всю інформацію
                  буде видалено.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="py-4 container-fluid" v-if="showDevFeatures">
    <div class="card card-body">
      <div class="row" v-if="showDevFeatures">
        <span class="badge bg-gradient-info">DEV</span>
        <div class="row">
          <div class="col-12 col-md-6 col-xl-4 position-relative">
            <hr class="vertical dark" />
          </div>
          <div class="col-12 col-md-6 col-xl-4 mt-md-0 mt-4 position-relative">
            <div class="card card-plain">
              <div class="card-header pb-0 p-3">
                <h6 class="mb-0">Змінити тарифний план</h6>
              </div>
              <div class="card-body p-3">
                <label> Оберіть кількість обʼєктів</label>
                <div class="input-group input-group-outline text-center">
                  <select
                    class="form-control text-center"
                    id="exampleFormControlSelect1"
                  >
                    <option>1 обʼєкт (250 грн/міс)</option>
                    <option>2 обʼєкт (500 грн/міс)</option>
                    <option>3 обʼєкт (750 грн/міс)</option>
                    <option>4 обʼєкт (1000 грн/міс)</option>
                    <option>5 обʼєкт (1250 грн/міс)</option>
                    <option>6 обʼєкт (1500 грн/міс)</option>
                    <option>7 обʼєкт (1750 грн/міс)</option>
                    <option>8 і більше обʼєктів (2000 грн/міс)</option>
                  </select>
                </div>
                <label> Оберіть період оплати</label>
                <div class="input-group input-group-outline text-center">
                  <select
                    class="form-control text-center"
                    id="exampleFormControlSelect1"
                  >
                    <option>1 місяць</option>
                    <option>6 місяців</option>
                    <option>12 місяців</option>
                  </select>
                </div>
                <div class="form-check customCheckbox">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="fcustomCheck1"
                    checked=""
                  />
                  <label class="custom-control-label ms-2" for="customCheck1"
                    >Менеджер каналів (500 грн на міс / 1000 грн на міс)</label
                  >
                  Менеджер каналів (500 грн на міс / 1000 грн на міс)
                </div>
                <hr />
                <p class="text-sm">
                  Вартість: 750 грн <br />
                  Комісія платіжної системи: 9,75 грн <br />
                  Сума до сплати: 759,75 грн
                </p>
              </div>
              <div class="row p-3">
                <button class="btn btn-md btn-primary" type="button">
                  Сплатити
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">тут табличка така сама як в СМС в поповненнях</div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import Spinner from "@/views/shared/Spinner.vue";

export default {
  name: "ecommerce-subscription",

  components: {
    Spinner,
  },

  data() {
    return {
      showDevFeatures: false,
      dataLoaded: false,

      company: {
        forcePaymentEnabled: false,
        forcePaymentAmount: null,
        forcePaymentUrl: null,
      },
    };
  },

  async created() {
    this.company = await api.company.show();
    this.dataLoaded = true;
  },

  beforeMount() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
  },

  methods: {},
};
</script>

<style scoped>
.trial {
  background-color: #bfdbca;
  border-radius: 16px;
}
.customCheckbox {
  padding-left: 0px;
  padding-top: 8px;
}
</style>
