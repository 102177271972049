import * as _ from "lodash";
import Choices from "choices.js";
import { DateTime } from "luxon";
import useClipboard from "vue-clipboard3";
import flatPickr from "vue-flatpickr-component";
import { Ukrainian } from "flatpickr/dist/l10n/uk.js";
import api from "@/services/api";
import checkUserRole from "@/services/checkUserRole";
import timeDisplay from "@/utils/timeDisplay";
import constants from "@/constants";
import validator from "@/utils/validator";
import delay from "@/utils/delay";
import * as timeSlotConverter from "@/utils/timeSlotConverter";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";
import Spinner from "@/views/shared/Spinner.vue";
import QrCode from "vue-qrcode-component";
import SmallLoader from "@/views/shared/SmallLoader.vue";
import Multiselect from "vue-multiselect/src/Multiselect.vue";
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import ButtonIcon from "@/components/common/other/ButtonIcon.vue";
import EyeIcon from "@/components/icons/EyeIcon.vue";
import ColorRadioButtons from "@/components/common/inputs/ColorRadioButtons.vue";
import { colorsOrderList } from "@/utils/lists/colorsOrder";
import { mask } from "vue-the-mask";
import { ISODateToDay } from "@/utils/time";
import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";
import Modal from "bootstrap/js/dist/modal";

const RE_PHONE_NUMBER = /^\d{10}$/;

const PLACEHOLDER_NEW_CLIENT = "#add-new-client#";

let clientLookupTimeout = null;

const DURATION_ONE_DAY = {
  days: 1,
};

export default {
  name: "new-order",
  directives: { mask },

  async created() {
    this.company = await api.company.show();
    if (this.company.crmMode === "hotel") {
      this.calendarConfig.mode = "range";
    }
  },

  async mounted() {
    const initConfig = await api.serviceOrders.getConfig();
    const branches = await api.branches.list();
    const company = await api.company.show();
    this.branch = branches[0];
    this.calendarConfig.minDate = initConfig.calendar.minDate;
    this.calendarConfig.maxDate = initConfig.calendar.maxDate;
    this.calendarConfig.disable = [];

    this.initConfig.maxDate = initConfig.calendar.maxDate;

    const services = await api.services.list({
      isActive: 1,
    });

    this.showGroupSwitcher = services.some((service) => {
      return service.groupEnabled;
    });

    if (this.$route.query.groupEnabled === "1") {
      this.groupEnabled = true;
    }

    if (this.$route.params.id) {
      this.mode = "edit";

      this.orderTemplate = await api.serviceOrders.findOne(
        this.$route.params.id
      );
      this._originalOrderTemplate = {
        ...this.orderTemplate,
      };

      if (!this.orderTemplate.color) {
        this.orderTemplate.color = "";
      }
      this.groupEnabled = this.orderTemplate.groupEnabled || false;
    }

    const filterIncludeRemoved = {
      includeRemoved: "1",
    };

    const servicesFilter = {
      ...filterIncludeRemoved,
      groupEnabled: this.groupEnabled ? "1" : "0",
    };

    this.staff = await api.staff.list(filterIncludeRemoved);
    this.staffByIdMap = _.keyBy(this.staff, "_id");
    this.services = await api.services.list(servicesFilter);
    this.servicesByIdMap = _.keyBy(this.services, "_id");

    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");

    if (this.$route.params.id) {
      this.existingClientsByIdMap = {};
      this.orderTemplate.clients.forEach((client) => {
        if (!client?.clientId) {
          return false;
        }

        this.existingClientsByIdMap[client.clientId._id] = {
          clientId: client.clientId._id,
          _id: client.clientId._id,
          _isRemoved: false,
          firstName: client.clientId.firstName,
          lastName: client.clientId.lastName,
          phoneNumber: client.clientId.phoneNumber,
        };
      });
      this.orderTemplate.clients = this.orderTemplate.clients.map((client) => {
        return {
          clientId: client.clientId?._id,
          _id: client.clientId?._id,
          _isRemoved: false,
        };
      });

      this.orderTemplate.history = this.orderTemplate.history.sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        return 0;
      });

      if (this.orderTemplate.status === "personal") {
        this.isPersonal = true;
        this.orderTemplate.services = [];
      }

      if (this.company.crmMode === "hotel") {
        this.orderTemplate.serviceDateTo = DateTime.fromISO(
          this.orderTemplate.serviceDateTo
        )
          .plus(DURATION_ONE_DAY)
          .toISODate();
      }
      if (this.orderTemplate.services.length) {
        this.orderTemplate.services.forEach((service) => {
          service._isRemoved = false;
        });
      }

      this.showPriceField = true;

      if (this.company.crmMode !== "hotel") {
        const parsed = timeSlotConverter
          .toString(this.orderTemplate.serviceTime)
          .split(":");
        this.startTimeAsString.hours = parsed[0];
        this.startTimeAsString.minutes = parsed[1];

        setTimeout(() => {
          if (this.orderTemplate.customTotalDuration) {
            const parsed = timeSlotConverter
              .toString(
                this.orderTemplate.serviceTime +
                  this.orderTemplate.customTotalDuration
              )
              .split(":");
            this.endTimeAsString.hours = parsed[0];
            this.endTimeAsString.minutes = parsed[1];
          } else {
            this.refreshEndTime();
          }
          this.showDurationField = true;
        }, 1500);
      }
    } else {
      this.mode = "create";
      this.orderTemplate.smsNotifyNewServiceOrder =
        company.smsNotifyOnNewServiceOrderFromApp || false;
      this.showPriceField = true;
      this.showDurationField = true;
    }

    const preExistingServices =
      this.mode === "edit"
        ? this.orderTemplate.services.map((service) => {
            return service.serviceId;
          })
        : [];

    this.services = this.services.filter((service) => {
      if (service.isActive) {
        return true;
      }

      if (preExistingServices.includes(service._id)) {
        return true;
      }

      return false;
    });

    this.staff = this.staff.filter((staff) => {
      if (staff.isActive) {
        return true;
      }

      if (this.mode === "edit" && staff._id === this.orderTemplate.staffId) {
        return true;
      }

      return false;
    });

    const staffId =
      this.orderTemplate.staffId ||
      this.$route.query.staffId ||
      this.staff[0]._id;

    const staff = this.staff.find((item) => {
      return item._id === staffId;
    });

    let _preselected = false;

    this.serviceOptions = this.services.map((item) => {
      const label = this.showDuration()
        ? `${item.name} (${this.displayDuration(item.duration)})`
        : item.name;

      const price = item.prices.find((subItem) => {
        return subItem.positionId?._id === staff.positionId._id;
      })?.price;

      let isDisabled = !item.isActive;

      let selected = false;
      if (!_preselected && !!price) {
        selected = true;
        _preselected = true;
      }

      return {
        value: item._id,
        label,
        selected,
        disabled: isDisabled,
        price,
      };
    });

    this.serviceOptions = this.serviceOptions.filter((item) => {
      return !!item.price;
    });

    this.dataLoaded = true;

    setTimeout(autoResizeTextarea, 1000, {
      target: this.$refs.description,
    });

    await delay(200);

    const selectorStatus = document.getElementById("selector-status");
    if (selectorStatus) {
      new Choices(selectorStatus, {
        allowHTML: true,
        searchEnabled: false,
      });
    }

    // TODO: implement more optimizations: https://github.com/Choices-js/Choices/issues/162

    if (this.orderTemplate.staffId) {
      const staff = await api.staff.findOne(this.orderTemplate.staffId);
      this.orderDetails.staff = staff.name || "-";
    }

    if (
      this.orderTemplate.serviceDateFrom &&
      this.orderTemplate.serviceDateTo
    ) {
      this.orderDetails.datetime = `${this.timestampToString(
        this.orderTemplate.serviceDateFrom
      )} - ${this.timestampToString(this.orderTemplate.serviceDateTo)}`;
    } else if (this.orderTemplate.serviceDate) {
      this.orderDetails.datetime = `${this.timestampToString(
        this.orderTemplate.serviceDate
      )} ${timeSlotConverter.toString(this.orderTemplate.serviceTime)}`;
    }

    if (this.orderTemplate.services?.length) {
      const service = await api.services.findOne(
        this.orderTemplate.services[0].serviceId
      );
      const prefix =
        this.orderTemplate.services.length > 1
          ? `(+${this.orderTemplate.services.length - 1})`
          : "";
      this.orderDetails.services = service.name;
      this.orderDetails.servicesPrefix = prefix;
    }

    const selectorStaff = document.getElementById("selector-staff");
    const staffOptions = this.staff.map((item) => {
      const label = `${item.name} (${item.positionId.name})`;
      return {
        value: item._id,
        label,
        selected: item.value === this.orderTemplate.staffId,
        disabled: !item.isActive,
      };
    });

    const choicerStaff = new Choices(selectorStaff, {
      allowHTML: false,
      searchEnabled: true,
      removeItemButton: false,
      choices: staffOptions,
      shouldSort: false,
    });

    if (this.mode === "create") {
      // accept passed params
      const staffId = this.$route.query.staffId;
      const serviceDate = this.$route.query.serviceDate || null;
      const serviceTime = this.$route.query.serviceTime || null;

      this.orderTemplate.staffId = staffId;
      const preSelectStaff = this.staff.find((item) => {
        if (this.orderTemplate.staffId) {
          return this.orderTemplate.staffId === item._id;
        }

        if (item.isActive) {
          this.orderTemplate.staffId = item._id;
          return true;
        }

        return false;
      });
      if (preSelectStaff) {
        choicerStaff.setChoiceByValue(preSelectStaff._id);
      }

      this.orderTemplate.serviceDate =
        serviceDate || DateTime.now().toISODate();
      this.orderTemplate.serviceTime = parseInt(serviceTime, 10);

      if (this.company.crmMode === "hotel" && !serviceDate) {
        this.orderTemplate.serviceDate = null;
      }
      if (this.$route.query.clientID) {
        await this.addClient({ clientId: this.$route.query.clientID }, 0, true);
      } else {
        await this.addClient();
      }
      this.addService();

      if (serviceTime) {
        const parsed = timeSlotConverter
          .toString(this.orderTemplate.serviceTime)
          .split(":");
        this.startTimeAsString.hours = parsed[0];
        this.startTimeAsString.minutes = parsed[1];
      }
    } else {
      choicerStaff.setChoiceByValue(this.orderTemplate.staffId);

      const _clients = this.orderTemplate.clients?.length
        ? this.orderTemplate.clients
        : [{ clientId: this.orderTemplate.clientId }];

      _clients.forEach((item, index) => {
        this.addClient(item, index + 1);
      });

      this.orderTemplate.services.forEach((service, index) => {
        this.addService(service, index + 1);
      });
    }

    const invoiceModalElement = this.$refs.modalInvoiceSms;
    if (invoiceModalElement) {
      this.modalInvoiceSms = new Modal(invoiceModalElement.modalRef, {});
    }

    await this.init();
  },

  data() {
    return {
      newClientTemplate: {
        linkInstagram: "",
        linkTelegram: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
      },
      openNewClientFields: false,
      _serviceOptionsChoicers: [],

      isLoadingPayment: false,
      isLoadingDiscount: false,
      currentPayment: "",
      showDevFeatures: false,
      mode: "", // create | edit
      dataLoaded: false,
      showPriceField: false,
      showDurationField: false,
      colorsOrderList: colorsOrderList,
      showGroupSwitcher: false,
      showChildrenInput: false,

      company: {
        crmMode: "",
        localizationMode: "",
      },
      branch: {
        paymentMonobankEnabled: false,
      },
      initConfig: {},

      defaultPhoneCode: constants.defaultPhoneCode,

      groupEnabled: false,
      isLoading: false,
      isSaveActive: false,
      newClient: {
        firstName: "",
        lastName: "",
        phoneCode: constants.defaultPhoneCode,
        phoneNumber: "",
      },

      newClientModal: {
        firstName: "",
        lastName: "",
        linkTelegram: "",
        linkInstagram: "",
        phoneNumber: "",
        refChoiceInstance: null,
        elementIndex: null,
        errorMessage: false,
      },

      serviceOptions: [],

      doPaymentAmount: null,
      doDiscountAmount: null,

      priceBreakdown: {
        discountAmount: 0,
        fixedFinalPrice: 0,
      },

      _originalOrderTemplate: {},
      orderTemplate: {
        externalId: null,
        externalName: null,
        isOverbooked: false,
        status: "new",
        clientId: "",
        clients: [],
        color: "",
        staffId: "",
        services: [],
        additionalServices: [],
        serviceDate: null,
        serviceTime: null,

        guests: {
          adults: 2,
          children: 0,
        },

        totalDuration: null,
        customTotalDuration: null,
        totalPrice: null,
        smsNotifyNewServiceOrder: false,
        comment: "",

        isConfirmed: false,
        history: [],

        discounts: [],
        payments: [],
        paidAmount: 0,
        refundedAmount: 0,
        finalPrice: 0,
        fixedFinalPrice: 0,
      },
      isPersonal: false,

      additionalServices: [],

      // guestsMaxOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      guestsMaxOptions: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
        57, 58, 59, 60,
      ],
      additionalServicesQuantityOptions: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ],

      startTimeAsString: {
        hours: "",
        minutes: "",
      },
      endTimeAsString: {
        hours: "",
        minutes: "",
      },

      availableSlots: [],
      clients: [],
      staff: [],
      staffByIdMap: {},
      services: [],
      servicesByIdMap: {},
      _calendarPrevElement: {
        index: null,
        value: null,
      },
      _restored: true,
      calendarConfig: {
        mode: "single",
        dateFormat: "Y-m-d",
        // altFormat: "d-m-Y",
        locale: Ukrainian,
        inline: true,
        // allowInvalidPreload: true,
        disableMobile: true,
        onChange: async (selectedDates, selectedString) => {
          if (this.company.crmMode !== "hotel") {
            return;
          }
          if (!selectedDates) {
            return;
          }

          await this.refreshServicePrices();

          let rangeStart = null;
          let rangeEnd = null;

          const parts = (selectedString || "").split(" ");
          if (parts.length === 1) {
            rangeStart = parts[0];
          }
          if (parts.length === 3) {
            rangeStart = parts[0];
            rangeEnd = parts[2];
          }

          if (selectedDates && selectedDates.length !== 2) {
            rangeEnd = null;
          }

          if (!rangeStart) {
            return;
          }

          if (rangeStart && selectedDates.length === 1 && !this._restored) {
            this.restoreCalendar();
          }

          if (rangeStart && selectedDates.length === 2 && !rangeEnd) {
            if (!this._restored) {
              this.restoreCalendar();
            }
            this.$refs.datePickerWrap.fp.clear();
            return;
          }

          if (rangeStart && !rangeEnd && this._restored) {
            const tmpIndex = this.calendarConfig.disable.findIndex((item) => {
              return item > rangeStart;
            });
            let tmpValue = null;
            if (tmpIndex > -1) {
              tmpValue = this.calendarConfig.disable[tmpIndex];
              const nextDayDate = DateTime.fromISO(tmpValue)
                .plus(DURATION_ONE_DAY)
                .toISODate();
              this.calendarConfig.disable[tmpIndex] = nextDayDate;
              this._calendarPrevElement.index = tmpIndex;
              this._calendarPrevElement.value = tmpValue;
              this.calendarConfig.maxDate = nextDayDate;
              this._restored = false;
            }
          } else if (rangeStart && rangeEnd && rangeStart !== rangeEnd) {
            if (
              !this._restored &&
              rangeEnd !== this._calendarPrevElement.value
            ) {
              this.restoreCalendar();
            }
          } else {
            if (!this._restored && selectedDates.length === 2) {
              this.restoreCalendar();
            }
          }
        },
        // minDate: "{{today}}",
        // maxDate: "{{+6 month}}",
        // enable: [],
        // disable: [],
      },

      orderDetails: {
        client: "",
        services: "",
        servicesPrefix: "",
        staff: "",
        datetime: "",
      },
      selectedQrUrl: null,
      modalParamsQrCode: {
        modalId: "modal-default-3",
        modalTitle: "QR Code для оплати",
        modalDescription: "Відскануйте QR Code для здійснення оплати",
        modalButtonCancelText: "Ок",
        modalButtonConfirmText: "false",
        data: {},
        cancelHandler: () => {
          this.selectedQrUrl = null;
        },
      },
      modalParamsRemoveServiceOrder: {
        modalId: "modal-default-1",
        modalTitle: "Видалення",
        modalDescription:
          "Ви впевнені, що хочете видалити цю бронь? Після видалення відновлення буде неможливо.",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Видалити",
        data: {},
        confirmHandler: async () => {
          try {
            await api.serviceOrders.removeOne(this.$route.params.id);

            this.$store.commit("addToast", {
              title: "Збережено",
            });

            const dateRange = this.$refs.datePickerWrap.fp.selectedDates;
            const serviceDateFrom = DateTime.fromJSDate(
              dateRange[0]
            ).toISODate();

            await this.$router.push(
              `/ecommerce/calendar?preselectedDate=${serviceDateFrom}`
            );
          } catch (error) {
            this.$store.commit("addToast", {
              title: "Виникла помилка. Спробуйте ще раз",
              type: "error",
            });
          }
        },
      },

      modalParamsRemoveDiscountTransaction: {
        modalId: "modal-remove-discount-1",
        modalTitle: "Видалити оплату",
        modalDescription:
          "Ви впевнені, що хочете видалити цю знижку? Після видалення цієї знижки відновити її буде неможливо.",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Видалити",
        data: {
          discountId: null,
        },
        confirmHandler: async (data) => {
          await this.removeDiscount(data.discountId);
        },
      },

      modalParamsRemovePaymentTransaction: {
        modalId: "modal-default-2",
        modalTitle: "Видалити оплату",
        modalDescription:
          "Ви впевнені, що хочете видалити цю оплату? Після видалення цієї оплати відновити її буде неможливо.",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Видалити",
        data: {
          paymentId: null,
        },
        confirmHandler: async (data) => {
          await this.removePayment(data.paymentId);
        },
      },
      modalParamsRefundPaymentTransaction: {
        modalId: "modal-default-4",
        modalTitle: "Повернути оплату",
        modalDescription:
          "Ви впевнені, що хочете повернути цю оплату? Після повернення відмінити цю дію буде неможливо.",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Повернути",
        data: {
          paymentId: null,
          amount: null,
        },
        confirmHandler: async (data) => {
          await this.refundPayment(data.paymentId, data.amount);
        },
      },

      modalInvoiceSms: null,
      modalParamsInvoiceSms: {
        modalId: "modal-invoice-sms",
        modalTitle: " Відправити інвойс по SMS",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Відправити",
        confirmButtonColor: "primary",
        data: {
          paymentId: null,
          amount: null,
          paymentUrl: null,
          text: null,
          phoneNumber: null,
        },
        confirmHandler: async (data) => {
          await this.sendInvoiceInstantMessage();
        },
      },
    };
  },
  computed: {
    isInvalidPaymentAmount() {
      return !parseInt(this.doPaymentAmount);
    },
    isInvalidDiscountAmount() {
      return !parseInt(this.doDiscountAmount);
    },
    serviceDateFrom() {
      const dateFrom = this.orderTemplate.serviceDate?.split("to")[0];
      return dateFrom?.trim() || undefined;
    },
    serviceDateTo() {
      const dateTo = this.orderTemplate.serviceDate?.split("to")[1];
      return dateTo?.trim() || undefined;
    },
    serviceDays() {
      if (!this.serviceDateTo) {
        return undefined;
      }
      const dateFrom = DateTime.fromISO(this.serviceDateFrom);
      const dateTo = DateTime.fromISO(this.serviceDateTo);

      const days = dateTo.diff(dateFrom, ["days"]).toObject().days;

      return days;
    },
  },
  components: {
    EyeIcon,
    ButtonIcon,
    ColorRadioButtons,
    ButtonApp,
    Multiselect,
    SmallLoader,
    QrCode,
    BmnCustomPopup,
    flatPickr,
    Spinner,
  },

  methods: {
    autoResizeTextarea,
    async resetOverbookingFlag() {
      try {
        const payload = {
          isOverbooked: false,
        };
        await api.serviceOrders.updateOne(this.$route.params.id, payload);
        this.$store.commit("addToast", {
          title: "Збережено",
        });
        this.orderTemplate.isOverbooked = false;
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    onPastePhoneNumber(event, fromModal = false) {
      event.preventDefault();
      const clipboard = event.clipboardData;
      const text = clipboard.getData("Text");
      if (fromModal) {
        this.newClientModal.phoneNumber = this.normalizePhoneNumber(text);
      } else {
        this.newClientTemplate.phoneNumber = this.normalizePhoneNumber(text);
      }
    },
    resetDate() {
      this.orderTemplate.serviceDate = "";
      this.orderTemplate.totalPrice = undefined;
      this.restoreCalendar();
    },

    ISODateToDay,

    isAllowedDeleteServiceOrder() {
      if (this.mode !== "edit") {
        return false;
      }
      if (this.showDevFeatures) {
        return true;
      }
      if (checkUserRole("owner")) {
        return true;
      }
      if (this.orderTemplate.status !== "personal") {
        return true;
      }
      return false;
    },

    isAllowedDeleteDiscount(discount) {
      return checkUserRole("owner") || checkUserRole("root");
    },

    isAllowedDeletePayment(payment) {
      if (
        payment.operationType === "monobank-acquiring" &&
        ["success", "reversed"].includes(payment.status)
      ) {
        return false;
      }

      if (
        payment.operationType === "liqpay-acquiring" &&
        ["success", "reversed"].includes(payment.status)
      ) {
        return false;
      }

      if (payment.operationType === "refund") {
        return false;
      }

      return checkUserRole("owner") || checkUserRole("root");
    },

    isAllowedRefundPayment(payment) {
      if (this.mode === "create") {
        return false;
      }

      if (payment.operationType === "refund") {
        return false;
      }

      if (
        payment.operationType === "monobank-acquiring" &&
        !["success", "reversed"].includes(payment.status)
      ) {
        return false;
      }

      if (
        payment.operationType === "liqpay-acquiring" &&
        !["success", "reversed"].includes(payment.status)
      ) {
        return false;
      }

      if (!payment.amount || payment.amount <= 0) {
        return false;
      }

      return checkUserRole("owner") || checkUserRole("root");
    },

    showDateTime(input) {
      if (!input) {
        return "-";
      }
      return DateTime.fromISO(input)
        .setLocale("uk-UA")
        .toFormat("dd.MM.yyyy HH:mm");
    },

    removeDiscountTransactionPreHook(discountId) {
      this.modalParamsRemoveDiscountTransaction.data.discountId = discountId;
    },
    removePaymentTransactionPreHook(paymentId) {
      this.modalParamsRemovePaymentTransaction.data.paymentId = paymentId;
    },

    refundPaymentTransactionPreHook(paymentId, amount) {
      this.modalParamsRefundPaymentTransaction.data.paymentId = paymentId;
      this.modalParamsRefundPaymentTransaction.data.amount = amount;
    },

    async toggleGroupEnabledAndRefresh() {
      const targetValue = !this.groupEnabled;
      const value = targetValue ? "1" : "0";

      const query = { ...this.$route.query, groupEnabled: value };
      await this.$router.replace({ query });
      await this.$router.go();
    },

    async init() {
      await this.onStaffChange(true);

      await this.refreshServicePrices();

      await this.refreshCalendarDates(true);

      if (this.mode === "create") {
        await this.refreshTotalPriceAndDuration(true);
      }

      if (this.$route.query.serviceDate) {
        this.$refs.datePickerWrap.fp.setDate(
          [this.$route.query.serviceDate],
          true
        );
        await this.calendarConfig.onChange();
      }

      if (
        this.orderTemplate.serviceDateFrom &&
        this.orderTemplate.serviceDateTo
      ) {
        this.$refs.datePickerWrap.fp.setDate(
          [
            this.orderTemplate.serviceDateFrom,
            this.orderTemplate.serviceDateTo,
          ],
          true
        );
        setTimeout(() => {
          this.$refs.datePickerWrap.fp.setDate([
            this.orderTemplate.serviceDateFrom,
            this.orderTemplate.serviceDateTo,
          ]);
        }, 100);
      }
    },

    restoreCalendar() {
      this.calendarConfig.disable[this._calendarPrevElement.index] =
        this._calendarPrevElement.value;
      this._calendarPrevElement.index = null;
      this._calendarPrevElement.value = null;
      this._restored = true;

      this.calendarConfig.maxDate = this._calendarPrevElement.initialMaxDate;
    },

    showDuration() {
      return this.company.crmMode !== "hotel";
    },

    isValidStartTime() {
      const hours = this.startTimeAsString.hours;
      const minutes = this.startTimeAsString.minutes;

      const timeslot = timeSlotConverter.toNumber(`${hours}:${minutes}`);
      if (!timeslot || timeslot < 0 || timeslot >= 1440) {
        this.endTimeAsString.hours = "";
        this.endTimeAsString.minutes = "";

        return false;
      }

      return true;
    },

    isValidEndTime() {
      const hours = this.endTimeAsString.hours;
      const minutes = this.endTimeAsString.minutes;

      const timeslotStart = timeSlotConverter.toNumber(
        `${this.startTimeAsString.hours}:${this.startTimeAsString.minutes}`
      );
      const timeslotEnd = timeSlotConverter.toNumber(`${hours}:${minutes}`);

      if (
        timeslotStart === null ||
        !timeslotEnd === null ||
        timeslotStart >= timeslotEnd
      ) {
        return false;
      }

      return true;
    },

    onStartTimeChange() {
      if (!this.isValidStartTime()) {
        return;
      }

      this.refreshEndTime();
    },

    refreshEndTime() {
      if (this.company.crmMode === "hotel") {
        return;
      }
      if (!this.isValidStartTime()) {
        return;
      }

      const hours = this.startTimeAsString.hours;
      const minutes = this.startTimeAsString.minutes;
      const totalDuration = this.isPersonal
        ? 60
        : this.orderTemplate.totalDuration;

      const startTimeslot = timeSlotConverter.toNumber(`${hours}:${minutes}`);
      const endTimeslot = startTimeslot + totalDuration;

      const endTime = timeSlotConverter.toString(endTimeslot);
      const parsed = endTime.split(":");
      this.endTimeAsString.hours = parsed[0];
      this.endTimeAsString.minutes = parsed[1];
    },

    setStatus(status) {
      if (!["new", "done", "cancelled"].includes(status)) {
        return;
      }
      this.orderTemplate.status = status;
    },

    async _beforeSave() {
      const payload = { ...this.orderTemplate };

      payload.groupEnabled = this.groupEnabled;
      payload.serviceTime = timeSlotConverter.toNumber(
        `${this.startTimeAsString.hours}:${this.startTimeAsString.minutes}`
      );

      const timeslotTo = timeSlotConverter.toNumber(
        `${this.endTimeAsString.hours}:${this.endTimeAsString.minutes}`
      );
      const _customTotalDuration = timeslotTo - payload.serviceTime;

      if (this.isPersonal) {
        payload.totalDuration = 60;
      }

      payload.customTotalDuration =
        payload.totalDuration !== _customTotalDuration
          ? _customTotalDuration
          : null;

      if (!_.isNumber(payload.totalDuration)) {
        payload.totalDuration = 0;
      }

      if (this.isPersonal) {
        payload.status = "personal";
        payload.services = null;
        payload.additionalServices = [];
        payload.clientId = null;
        payload.clients = [];
        payload.totalDuration = 60;
        payload.smsNotifyNewServiceOrder = false;

        if (this.company.crmMode === "hotel") {
          payload.serviceTime = 0;
          payload.services = [];

          const dateRange = this.$refs.datePickerWrap.fp.selectedDates;
          payload.serviceDateFrom = DateTime.fromJSDate(
            dateRange[0]
          ).toISODate();
          payload.serviceDateTo = DateTime.fromJSDate(dateRange[1])
            .minus(DURATION_ONE_DAY)
            .toISODate();
        }

        return payload;
      }

      payload.services = payload.services.filter((item) => {
        return item && item.serviceId && !item._isRemoved;
      });

      payload.clientId = null;

      if (this.openNewClientFields) {
        try {
          const clientPayload = {
            ...this.newClientTemplate,
          };
          if (this.newClientTemplate.phoneNumber.length !== 0) {
            clientPayload.phoneNumber =
              "+38" + this.newClientTemplate.phoneNumber;
          }

          clientPayload.source = "page-service-order";
          const createdClient = await api.clients.create(clientPayload);
          payload.isCreatedNewClient = true;
          payload.clients = [
            {
              clientId: createdClient._id,
              _id: createdClient._id,
              _isRemoved: createdClient.isRemoved,
            },
          ];
          this.$store.commit("addToast", {
            title: "Збережено",
          });
        } catch (error) {
          payload.isCreatedNewClient = false;
          this.$store.commit("addToast", {
            title:
              "Виникла помилка. Нового клієнта не створено, спробуйте ще раз",
            type: "error",
          });
        }
      } else {
        payload.clients = payload.clients.filter((client) => {
          return (
            client &&
            client.clientId &&
            !client._isRemoved &&
            client.clientId !== PLACEHOLDER_NEW_CLIENT
          );
        });
      }

      if (this.company.crmMode === "hotel") {
        payload.serviceTime = 0;

        const dateRange = this.$refs.datePickerWrap.fp.selectedDates;
        payload.serviceDateFrom = DateTime.fromJSDate(dateRange[0]).toISODate();
        payload.serviceDateTo = DateTime.fromJSDate(dateRange[1])
          .minus(DURATION_ONE_DAY)
          .toISODate();
        payload.serviceDate = payload.serviceDateFrom;
      }

      return payload;
    },

    async save() {
      this.isSaveActive = true;
      const payload = await this._beforeSave();
      if (this.openNewClientFields && !payload.isCreatedNewClient) {
        this.isSaveActive = false;
        return;
      }
      delete payload.isCreatedNewClient;

      let redirectToServiceId = null;

      try {
        let notifyOnCreatedStatus;

        if (this.company.crmMode === "hotel") {
          if (
            !payload.serviceDate ||
            !payload.serviceDateFrom ||
            !payload.serviceDateTo
          ) {
            this.$store.commit("addToast", {
              title: "Оберіть дати",
              type: "error",
            });
            return;
          }
        }

        if (this.mode === "create") {
          const result = await api.serviceOrders.create(payload);
          redirectToServiceId = result?._id;
          notifyOnCreatedStatus = result?.notifyOnCreated?.status;
        } else if (this.mode === "edit") {
          const result = await api.serviceOrders.updateOne(
            this.$route.params.id,
            payload
          );
          notifyOnCreatedStatus = result?.notifyOnCreated?.status;
          redirectToServiceId = result?._id;
        }

        if (notifyOnCreatedStatus === "internalInsufficientFunds") {
          this.$store.commit("addToast", {
            title: "Недостатньо смс-кредитів, поповніть, будь ласка, баланс",
            type: "error",
          });
        }

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
        return;
      } finally {
        this.isSaveActive = false;
      }
      if (redirectToServiceId) {
        const query = {
          ...this.$route.query,
        };

        const dateRange = this.$refs.datePickerWrap.fp.selectedDates;
        const serviceDateFrom = DateTime.fromJSDate(dateRange[0]).toISODate();

        await this.$router.replace({ query });
        await this.$router.push(
          `/ecommerce/calendar?serviceOrderId=${redirectToServiceId}&preselectedDate=${serviceDateFrom}`
        );
        return;
      }

      await this.$router.push("/service-orders");
    },

    async refreshCalendarDates() {
      if (this.orderTemplate.staffId) {
        const dates = await api.serviceOrders.getAvailableDates(
          this.orderTemplate.staffId,
          this.orderTemplate._id
        );
        if (this.company.crmMode === "hotel") {
          // this.calendarConfig.disable = dates.disable.sort(); // TODO: remove if we are happy with absence of dates disabling
          this.calendarConfig.disable = [];
        } else {
          this.calendarConfig.enable = dates;
        }
      }

      if (this.company.crmMode !== "hotel" && this.orderTemplate.serviceDate) {
        if (!_.isArray(this.calendarConfig.enable)) {
          this.calendarConfig.enable = [];
        }
        this.calendarConfig.enable.push(this.orderTemplate.serviceDate);
      }
    },

    showRemoveClientButton() {
      const notRemoved = this.orderTemplate.clients.filter((client) => {
        return !client._isRemoved;
      });
      return notRemoved.length > 1;
    },

    showRemoveServiceButton() {
      const notRemoved = this.orderTemplate.services.filter((service) => {
        return !service._isRemoved;
      });
      return notRemoved.length > 1;
    },

    preDeleteValidation() {
      if (this.showDevFeatures) {
        return true;
      }
      if (this.orderTemplate.externalId) {
        return false;
      }
      return true;
    },

    preSaveValidation() {
      if (this.orderTemplate.externalId) {
        return false;
      }

      if (this.isPersonal) {
        let isValidDate = false;
        if (this.company.crmMode === "hotel") {
          const dateRange = this.$refs.datePickerWrap.fp.selectedDates;
          const serviceDateFrom = DateTime.fromJSDate(dateRange[0]).toISODate();
          const serviceDateTo = DateTime.fromJSDate(dateRange[1])
            .minus(DURATION_ONE_DAY)
            .toISODate();

          isValidDate =
            serviceDateFrom &&
            serviceDateTo &&
            serviceDateFrom <= serviceDateTo;
        } else {
          isValidDate = this.isValidStartTime() && this.isValidEndTime();
        }

        return (
          this.orderTemplate.staffId &&
          this.orderTemplate.serviceDate &&
          isValidDate
        );
      }

      const clientsOk = this.orderTemplate.clients.every((client) => {
        if (client._isRemoved) {
          return true;
        }
        return client.clientId;
      });
      const amountOfClients = this.orderTemplate.clients.filter((client) => {
        return !client._isRemoved;
      }).length;
      if (!clientsOk && amountOfClients !== 1) {
        return false;
      }

      if (this.company.crmMode === "hotel") {
        const dateRange = _.get(
          this.$refs,
          "datePickerWrap.fp.selectedDates",
          null
        );
        if (!_.isArray(dateRange) || dateRange.length !== 2) {
          return false;
        }

        const rangeStart = DateTime.fromJSDate(dateRange[0]).toISODate();
        const rangeEnd = DateTime.fromJSDate(dateRange[1]).toISODate();
        if (this.calendarConfig.disable.includes(rangeStart)) {
          return false;
        }
        if (this.calendarConfig.disable.includes(rangeEnd)) {
          return false;
        }

        return this.orderTemplate.staffId && this.orderTemplate.serviceDate;
      }

      return (
        this.orderTemplate.staffId &&
        this.orderTemplate.services &&
        this.orderTemplate.services.length &&
        this.orderTemplate.serviceDate &&
        this.isValidStartTime() &&
        this.isValidEndTime()
      );
    },

    removeClient(client) {
      if (
        !this.orderTemplate.clients ||
        this.orderTemplate.clients.length < 2
      ) {
        return;
      }

      client._isRemoved = true;
    },

    async removeService(service) {
      // if (
      //   !this.orderTemplate.services ||
      //   this.orderTemplate.services.length < 2
      // ) {
      //   return;
      // }

      service._isRemoved = true;
      await this.refreshServicePrices();
    },

    async addClient(client, index, preselectClient = false) {
      if (!client) {
        this.orderTemplate.clients.push({
          clientId: null,
          _id: null,
          _isRemoved: false,
        });
      } else if (client && preselectClient) {
        this.orderTemplate.clients.push({
          clientId: client.clientId,
          _id: client.clientId,
          _isRemoved: false,
        });
        const _client = await api.clients.findOne(client.clientId);
        this.existingClientsByIdMap = {};
        if (_client) {
          this.existingClientsByIdMap[_client._id] = {
            clientId: _client._id,
            _id: _client._id,
            _isRemoved: false,
            firstName: _client.firstName,
            lastName: _client.lastName,
            phoneNumber: _client.phoneNumber,
          };
        }
      }

      setTimeout(async () => {
        const id = index
          ? `selector-client-${index}`
          : `selector-client-${this.orderTemplate.clients.length}`;
        const selectorClient = document.getElementById(id);
        if (selectorClient) {
          const choices = [
            {
              value: "",
              label: "Додати клієнта",
              selected: true,
              disabled: true,
              // value: PLACEHOLDER_NEW_CLIENT,
              // label: "+ новий клієнт",
              // selected: false,
              // disabled: false,
            },
          ];
          choices.push({
            value: PLACEHOLDER_NEW_CLIENT,
            label: "+ новий клієнт",
            selected: false,
            disabled: false,
          });
          if (
            client?.clientId &&
            this.existingClientsByIdMap[client.clientId]
          ) {
            const _client = this.existingClientsByIdMap[client.clientId];

            let label = "";
            if (_client.firstName || _client.lastName) {
              label = `${_client.firstName} ${_client.lastName}`;
            }
            if (_client.phoneNumber) {
              label = `${label} (${_client.phoneNumber})`;
            }
            if (!label) {
              label = "не обрано";
            }

            choices.push({
              value: _client._id,
              label,
              selected: true,
              disabled: false,
            });

            choices[0].selected = false;
          }

          const choiceInstance = new Choices(selectorClient, {
            allowHTML: true,
            duplicateItemsAllowed: false,
            searchEnabled: true,
            searchFloor: 2,
            loadingText: "Пошук клієнтів...",
            searchPlaceholderValue: "Ім'я або телефон",
            // noChoicesText: "+ новий клієнт",
            // noChoicesText: "Не змогли знайти клієнта. Натисніть сюди для створення нового (або натисніть ENTER)",
            searchChoices: false,
            shouldSort: false,
            removeItemButton: false,
            choices,
          });

          selectorClient.addEventListener("change", (e) => {
            if (e?.detail?.value === PLACEHOLDER_NEW_CLIENT) {
              const searchValue = document
                .getElementById(id)
                .closest(".choices")
                .querySelector(".choices__input--cloned").value;

              this.showNewClientModal(
                choiceInstance,
                index || this.orderTemplate.clients.length,
                searchValue
              );
            }
          });

          selectorClient.addEventListener("search", async (e) => {
            clearTimeout(clientLookupTimeout);
            clientLookupTimeout = setTimeout(async () => {
              const data = await api.clients.list({
                limit: 200,
                search: e.detail.value,
              });
              const formatted = data.map((client) => {
                return {
                  value: client._id,
                  label: `${client.firstName} ${client.lastName} (${client.phoneNumber})`,
                };
              });

              formatted.push({
                value: PLACEHOLDER_NEW_CLIENT,
                label: "+ новий клієнт",
                selected: false,
                disabled: false,
              });

              choiceInstance.setChoices(formatted, "value", "label", true);

              // if (!data?.length) {
              //   const _tmpSelector2 = document.getElementById(id)
              //     .closest(".choices")
              //     .querySelector(".has-no-choices");
              //
              //   _tmpSelector2.addEventListener("click", async (event) => {
              //     event.preventDefault();
              //
              //     const searchValue = document.getElementById(id)
              //       .closest(".choices")
              //       .querySelector(".choices__input--cloned")
              //       .value;
              //
              //     this.showNewClientModal(choiceInstance, index || this.orderTemplate.clients.length, searchValue);
              //   });
              // }
            }, 300);
          });

          const _tmpSelector = document
            .getElementById(id)
            .closest(".choices")
            .querySelector(".choices__input--cloned");
          _tmpSelector.addEventListener(
            "keydown",
            async (event) => {
              const searchValue = event.target.value;

              if (event.keyCode === 13 && searchValue !== "") {
                this.showNewClientModal(
                  choiceInstance,
                  index || this.orderTemplate.clients.length,
                  event.target.value
                );
                // selectorClient.input.value = '';
              }
            },
            false
          );

          if (client?.clientId) {
            const _client = await api.clients.findOne(client.clientId);

            let label = "";
            if (_client.firstName || _client.lastName) {
              label = `${_client.firstName} ${_client.lastName}`;
            }
            if (_client.phoneNumber) {
              label = `${label} (${_client.phoneNumber})`;
            }
            if (!label) {
              label = "не обрано";
            }

            choiceInstance.setChoices(
              [
                {
                  value: _client._id,
                  label,
                  // selected: true,
                  selected: false,
                  disabled: false,
                },
              ],
              "value",
              "label",
              true
            );

            this.orderDetails.client = label || "-";
          }
        }
      }, 250);
    },

    addService(service, index) {
      if (!service) {
        if (this.company.crmMode === "hotel") {
          service = this.services[0];
        } else {
          const _service = this.serviceOptions.find((item) => {
            return !!item.price;
          });
          service = this.services.find((item) => {
            return item._id === _service.value;
          });
        }

        this.orderTemplate.services.push({
          serviceId: service._id,
          _id: service._id,
          servicePrice: service.price,
          serviceDuration: service.duration,
          groupEnabled: service.groupEnabled,
          groupLimit: service.groupLimit,
          _isRemoved: false,
        });
      } else {
        service.serviceDuration = this.servicesByIdMap[service.serviceId];
        service.groupEnabled =
          this.servicesByIdMap[service.serviceId].groupEnabled;
        service.groupLimit = this.servicesByIdMap[service.serviceId].groupLimit;
      }

      setTimeout(async () => {
        const id = index
          ? `selector-service-${index}`
          : `selector-service-${this.orderTemplate.services.length}`;
        const selectorService = document.getElementById(id);
        if (selectorService) {
          let needDefault = true;
          const options = _.cloneDeep(this.serviceOptions).map((item) => {
            item.selected = item.value === service.serviceId;
            if (item.selected) {
              needDefault = false;
            }
            return item;
          });

          if (needDefault) {
            options[0].selected = true;
          }

          // eslint-disable-next-line no-unused-vars
          const singleServiceChoicer = new Choices(selectorService, {
            allowHTML: false,
            searchEnabled: true,
            removeItemButton: false,
            choices: options,
            shouldSort: false,
            searchResultLimit: 20,
          });
          this._serviceOptionsChoicers.push(singleServiceChoicer);
        }

        await this.refreshServicePrices();
      }, 100);
    },

    async refreshServicePrices() {
      const staff = this.staff.find((item) => {
        return item._id === this.orderTemplate.staffId;
      });

      this.orderTemplate.services.forEach((service) => {
        if (!this.orderTemplate.staffId) {
          return null;
        }

        if (!service && service._isRemoved) {
          return null;
        }
        if (!service.serviceId) {
          service.servicePrice = null;
          return null;
        }

        this.services.forEach((item) => {
          if (item._id === service.serviceId) {
            item.prices.forEach((subItem) => {
              if (subItem.positionId?._id === staff.positionId._id) {
                service.servicePrice = subItem.price;
                service.serviceDuration = item.duration;
              }
            });
          }
        });
      });

      if (this.company.crmMode === "general") {
        let _preselected = false;

        this.serviceOptions = this.services.map((item) => {
          const label = this.showDuration()
            ? `${item.name} (${this.displayDuration(item.duration)})`
            : item.name;

          const price = item.prices.find((subItem) => {
            return subItem.positionId?._id === staff.positionId._id;
          })?.price;

          let isDisabled = !item.isActive;

          let selected = false;
          if (!_preselected && !!price) {
            selected = true;
            _preselected = true;
          }

          return {
            value: item._id,
            label,
            selected,
            disabled: isDisabled,
            price,
          };
        });

        this.serviceOptions = this.serviceOptions.filter((item) => {
          return !!item.price;
        });
      }

      await this.refreshTotalPriceAndDuration();
    },

    getAdditionalServicesTotal(numberOfDays, numberOfAdultGuests) {
      let total = 0;
      this.orderTemplate.additionalServices.forEach((item) => {
        let iterationTotal = 0;

        if (item.price) {
          const priceMultiplier =
            (item.isPricePerDay ? numberOfDays : 1) *
            (item.isPricePerGuest ? numberOfAdultGuests : 1) *
            (item.quantity || 1);

          iterationTotal = item.price * priceMultiplier;
        }

        total += iterationTotal;
      });

      return total;
    },

    async refreshTotalPriceAndDuration() {
      const payload = {
        serviceOrderId: this.orderTemplate._id,
        staffId: this.orderTemplate.staffId,
        serviceDateFrom: null,
        serviceDateTo: null,
        guests: null,
        services: this.orderTemplate.services || [],
        additionalServices: this.orderTemplate.additionalServices,

        _draft: {
          discounts: [],
        },
      };

      payload.services = payload.services.filter((service) => {
        return !service._isRemoved;
      });

      if (this.company.crmMode === "hotel") {
        const dateRange = this.$refs.datePickerWrap.fp.selectedDates;
        if (dateRange.length > 1) {
          const dateFrom = DateTime.fromJSDate(dateRange[0]);
          const dateTo = DateTime.fromJSDate(dateRange[1]);

          payload.serviceDateFrom = dateFrom.toISODate();
          payload.serviceDateTo = dateTo.minus({ days: 1 }).toISODate();

          payload.guests = this.orderTemplate.guests;
        }
      }

      if (this.mode === "create") {
        payload._draft.discounts = this.orderTemplate.discounts;
      }

      const priceBreakdown = await api.serviceOrders.getPriceBreakdown(payload);
      this.priceBreakdown = priceBreakdown;

      this.orderTemplate.servicesCost = priceBreakdown.servicesCost;
      this.orderTemplate.servicesPrepaymentAmount =
        priceBreakdown.servicesPrepaymentAmount;
      this.orderTemplate.additionalServicesCost =
        priceBreakdown.additionalServicesCost;
      this.orderTemplate.additionalServicesPrepaymentAmount =
        priceBreakdown.additionalServicesPrepaymentAmount;

      this.orderTemplate.totalPrice = priceBreakdown.totalPrice;
      this.orderTemplate.finalPrice = priceBreakdown.finalPrice;
      this.orderTemplate.fixedFinalPrice = priceBreakdown.fixedFinalPrice;

      if (this.company.crmMode === "hotel") {
        return;
      }

      if (_.isEmpty(this.orderTemplate.services)) {
        this.orderTemplate.totalPrice = 0;
        this.orderTemplate.totalDuration = 0;
        return;
      }

      let duration = 0;

      this.orderTemplate.services.forEach((item) => {
        if (item._isRemoved) {
          return;
        }

        if (_.isString(item.servicePrice)) {
          item.servicePrice = _.toNumber(item.servicePrice);
        }
        if (_.isString(item.serviceDuration)) {
          item.serviceDuration = _.toNumber(item.serviceDuration);
        }
        if (_.isNumber(item.serviceDuration)) {
          duration += item.serviceDuration;
        }
      });

      this.orderTemplate.totalDuration = duration;

      this.onStartTimeChange();
    },

    refreshMaxGuests() {
      if (this.company.crmMode !== "hotel") {
        return;
      }

      const _staff = this.staffByIdMap[this.orderTemplate.staffId];
      const maxAdultGuests = _staff.checkinRules.guestsMax;

      this.guestsMaxOptions = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
        57, 58, 59, 60,
      ].filter((item) => {
        return item <= maxAdultGuests;
      });

      if (!this.guestsMaxOptions.includes(this.orderTemplate.guests.adults)) {
        this.orderTemplate.guests.adults =
          this.guestsMaxOptions[this.guestsMaxOptions.length - 1];
      }
    },

    async onStaffChange(isPageLoad) {
      let triggerOnChange = false;

      if (this.company.crmMode === "hotel" && !this._restored) {
        this.calendarConfig.disable[this._calendarPrevElement.index] =
          this._calendarPrevElement.value;
        this._calendarPrevElement.index = null;
        this._calendarPrevElement.value = null;
        this._restored = true;

        this.calendarConfig.maxDate = this.initConfig.maxDate;

        triggerOnChange = true;
      }

      this.refreshMaxGuests();

      if (this.company.crmMode === "hotel") {
        const _staff = this.staffByIdMap[this.orderTemplate.staffId];
        this.showChildrenInput =
          _staff?.checkinRules?.children?.enabled || false;

        if (!this.showChildrenInput) {
          this.orderTemplate.guests.children = 0;
        }
      }

      this.additionalServices = await api.staff.getAdditionalServices(
        this.orderTemplate.staffId
      );

      this.additionalServices.forEach((item) => {
        item.isSelected = item.isPreselected;
      });

      if (this.mode === "create" || !isPageLoad) {
        this.orderTemplate.additionalServices = [];

        this.additionalServices.forEach((item) => {
          if (item.isSelected) {
            this.onAdditionalServiceCheck(item);
          }
        });
      } else if (isPageLoad) {
        this.additionalServices.forEach((item) => {
          const isSelected = this.orderTemplate.additionalServices.some(
            (subItem) => {
              return item._id === subItem.serviceId;
            }
          );
          item.isSelected = isSelected;

          const existingItem = this.orderTemplate.additionalServices.find(
            (subItem) => {
              return item._id === subItem.serviceId;
            }
          );
          if (existingItem?.quantity) {
            item.quantity = existingItem.quantity;
          }
        });
      } else {
        //
      }

      if (isPageLoad !== true && this.company.crmMode !== "hotel") {
        await delay(500);

        const toBeRemoved = this._serviceOptionsChoicers.map((choicer) => {
          return choicer;
        });

        for (const __service of this.orderTemplate.services) {
          await this.removeService(__service);
        }

        toBeRemoved.forEach((choicer) => {
          choicer.destroy();
        });

        this.orderTemplate.services = [];

        const staff = this.staff.find((item) => {
          return item._id === this.orderTemplate.staffId;
        });

        this.serviceOptions = this.services.map((item) => {
          const label = this.showDuration()
            ? `${item.name} (${this.displayDuration(item.duration)})`
            : item.name;

          const price = item.prices.find((subItem) => {
            return subItem.positionId?._id === staff.positionId._id;
          })?.price;

          let isDisabled = !item.isActive;

          // let selected = false;

          return {
            value: item._id,
            label,
            selected: false,
            disabled: isDisabled,
            price,
          };
        });

        this.serviceOptions = this.serviceOptions.filter((item) => {
          return !!item.price;
        });

        const atLeastOneService = this.orderTemplate.services.some(
          (service) => {
            return !service._isRemoved;
          }
        );
        if (!atLeastOneService) {
          this.addService();
        }
      }

      await this.refreshCalendarDates();

      await delay(300);

      if (triggerOnChange) {
        await this.calendarConfig.onChange();
      }
    },

    async onAdditionalServiceCheck(additionalService) {
      const isChecked = additionalService.isSelected;
      const _id = additionalService._id;
      if (isChecked) {
        // add to `this.orderTemplate.additionalServices`
        this.orderTemplate.additionalServices.push({
          serviceId: _id,
          name: additionalService.name,
          price: additionalService.price,
          prepaymentAmount: additionalService.prepaymentAmount,
          isPricePerDay: additionalService.isPricePerDay,
          isPricePerGuest: additionalService.isPricePerGuest,
          isAvailableNoPreviousDayBookings:
            additionalService.isAvailableNoPreviousDayBookings,
          isAvailableNoNextDayBookings:
            additionalService.isAvailableNoNextDayBookings,
          prohibitPreviousDayBooking:
            additionalService.prohibitPreviousDayBooking,
          prohibitNextDayBooking: additionalService.prohibitNextDayBooking,
          quantity: additionalService.quantity,
        });
      } else {
        // remove from `this.orderTemplate.additionalServices`
        this.orderTemplate.additionalServices =
          this.orderTemplate.additionalServices.filter((item) => {
            return item.serviceId !== _id;
          });
      }

      await this.refreshTotalPriceAndDuration();
    },

    async onAdditionalServiceQuantityChange(additionalService) {
      const _id = additionalService._id;
      const quantity = additionalService.quantity;

      const existingItem = this.orderTemplate.additionalServices.find(
        (item) => {
          return item.serviceId === _id;
        }
      );

      if (existingItem) {
        existingItem.quantity = quantity;
      }

      await this.refreshTotalPriceAndDuration();
    },

    async onGuestsNumberChange() {
      await this.refreshTotalPriceAndDuration();
    },

    onIsPersonalChange() {
      this.refreshEndTime();
    },

    displayDuration(minutes) {
      return timeDisplay.minutesToHumanFormat(minutes);
    },

    displayDayOfWeek() {
      if (!this.orderTemplate.serviceDate) {
        return "-";
      }

      return DateTime.fromISO(this.orderTemplate.serviceDate).setLocale("uk")
        .weekdayLong;
    },

    timestampToString(input) {
      if (!input) {
        return "-";
      }
      const datetime = DateTime.fromISO(input);
      return datetime
        .setLocale("uk-UA")
        .toLocaleString({ month: "short", day: "numeric", year: "numeric" });
    },

    datetimeToString(date) {
      if (!date) {
        return "-";
      }
      const datetime = DateTime.fromISO(date);
      return datetime.setLocale("uk-UA").toLocaleString({
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hourCycle: "h23",
      });
    },

    getLabelStaff2() {
      const localizationMode = this.company.localizationMode;

      switch (true) {
        case localizationMode === "spa":
          return "Ресурс";
        case localizationMode === "hotel":
          return "Номер";
        default:
          return "Майстер";
      }
    },

    showNewClientModal(refChoiceInstance, elementIndex, value) {
      this.newClientModal.refChoiceInstance = refChoiceInstance;
      this.newClientModal.elementIndex = elementIndex;
      this.newClientModal.firstName = "";
      this.newClientModal.lastName = "";
      this.newClientModal.phoneNumber = "";
      this.newClientModal.errorMessage = "";
      this.newClientModal.linkTelegram = "";
      this.newClientModal.linkInstagram = "";

      let maybeNumber = value;
      if (maybeNumber) {
        if (
          maybeNumber.startsWith("+38") &&
          maybeNumber.length <= 13 &&
          maybeNumber.length >= 5
        ) {
          maybeNumber = maybeNumber.substr(3);
        } else if (
          maybeNumber.startsWith("38") &&
          maybeNumber.length <= 12 &&
          maybeNumber.length >= 5
        ) {
          maybeNumber = maybeNumber.substr(2);
        } else if (_.toNumber(maybeNumber)) {
          maybeNumber = value;
        } else {
          maybeNumber = null;
        }

        if (maybeNumber && maybeNumber.startsWith("+")) {
          maybeNumber = maybeNumber.substr(1);
        }
      }

      if (maybeNumber) {
        this.newClientModal.phoneNumber = maybeNumber;
      } else {
        this.newClientModal.firstName = value || "";
      }

      document.getElementById("ref-button-add-new-client").click();
    },

    async newClientModalCancel() {
      // await this.newClientModal.refChoiceInstance.setChoices([{
      //   value: PLACEHOLDER_NEW_CLIENT,
      //   label: "+ новий клієнт",
      //   selected: false,
      //   disabled: false,
      // }], "value", "label", true);
    },

    async newClientModalSave() {
      this.newClientModal.errorMessage = "";
      this.isLoading = true;

      const payload = {
        linkInstagram: this.newClientModal.linkInstagram,
        linkTelegram: this.newClientModal.linkTelegram,
        firstName: this.newClientModal.firstName,
        lastName: this.newClientModal.lastName,
        phoneNumber: null,
      };

      this.newClientModal.phoneNumber = this.normalizePhoneNumber(
        this.newClientModal.phoneNumber
      );

      if (validator.phoneNumber(this.newClientModal.phoneNumber)) {
        payload.phoneNumber = `+38${this.newClientModal.phoneNumber}`;
      }

      let client = null;
      try {
        payload.source = "page-service-order";
        client = await api.clients.create(payload);

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }

      if (client?.errorCode) {
        this.newClientModal.errorMessage = client.message;
        return;
      }

      if (client?._id && this.newClientModal.refChoiceInstance) {
        let label = "";
        if (client.firstName || client.lastName) {
          label = `${client.firstName} ${client.lastName}`;
        }
        if (client.phoneNumber) {
          label = `${label} (${client.phoneNumber})`;
        }
        if (!label) {
          label = "не обрано";
        }

        const items = [
          {
            value: client._id,
            label,
            selected: true,
            disabled: false,
          },
          {
            value: PLACEHOLDER_NEW_CLIENT,
            label: "+ новий клієнт",
            selected: false,
            disabled: false,
          },
        ];

        await this.newClientModal.refChoiceInstance.setChoices(
          items,
          "value",
          "label",
          true
        );

        setTimeout(() => {
          this.orderTemplate.clients[this.newClientModal.elementIndex - 1] = {
            _id: client._id,
            clientId: client._id,
            _isRemoved: false,
          };
          document.getElementById("modal-new-client-close-button").click();
        }, 100);
      }
    },

    normalizePhoneNumber(input) {
      if (!input) {
        return "";
      }
      const normalized = input.replace(/\D/g, "");
      if (!normalized) {
        return "";
      }
      if (normalized.length === 12 && normalized.startsWith("38")) {
        return normalized.slice(2);
      }
      return normalized;
    },

    isValidPhoneNumber() {
      const phoneNumber = this.newClientModal.phoneNumber;

      if (!phoneNumber) {
        return true;
      }

      const input = this.normalizePhoneNumber(phoneNumber);

      if (!RE_PHONE_NUMBER.test(input)) {
        return false;
      }
      return true;
    },

    isValidPhoneNumberNewClient() {
      const phoneNumber = this.newClientTemplate.phoneNumber;

      if (!phoneNumber) {
        return true;
      }

      const input = this.normalizePhoneNumber(phoneNumber);

      if (!RE_PHONE_NUMBER.test(input)) {
        return false;
      }
      return true;
    },

    isMaxClientsReached() {
      if (!this.groupEnabled) {
        return false;
      }

      const service = this.orderTemplate.services[0];
      if (!service) {
        return false;
      }

      if (!service.groupEnabled || !service.groupLimit) {
        return false;
      }

      const notRemoved = this.orderTemplate.clients.filter((client) => {
        return !client._isRemoved;
      });

      return notRemoved.length >= service.groupLimit;
    },

    getTotalPrice() {
      return this.orderTemplate.fixedFinalPrice;
    },

    getAmountToPayLeft() {
      if (this.mode === "create") {
        return (
          this.priceBreakdown.finalPrice -
          (this.orderTemplate.paidAmount || 0) +
          (this.orderTemplate.refundedAmount || 0)
        );
      }

      if (this.mode === "edit") {
        return (
          this._originalOrderTemplate.finalPrice -
          (this.orderTemplate.paidAmount || 0) +
          (this.orderTemplate.refundedAmount || 0)
        );
      }
    },

    getL0() {
      if (this.mode === "create") {
        return this.priceBreakdown.totalPrice;
      }
      if (this.mode === "edit") {
        const sum =
          this._originalOrderTemplate.servicesCost +
          this.orderTemplate.additionalServicesCost;
        return sum;
      }
    },
    getL1() {
      if (this.mode === "create") {
        return this.priceBreakdown.servicesCost;
      }
      if (this.mode === "edit") {
        return this._originalOrderTemplate.servicesCost;
      }

      // return (
      //   // this.priceBreakdown.fixedFinalPrice + this.priceBreakdown.discountAmount
      //   this.priceBreakdown.servicesCost
      // );
    },
    getL2() {
      return this.priceBreakdown.additionalServicesCost;
    },
    getL3() {
      return this.priceBreakdown.discountAmount;
    },
    getL4() {
      // return this.priceBreakdown.totalPrice;
      if (this.mode === "create") {
        return (
          this.priceBreakdown.fixedFinalPrice -
          this.priceBreakdown.paidAmount -
          this.priceBreakdown.refundedAmount
        );
      }
      if (this.mode === "edit") {
        const sumToPay =
          this._originalOrderTemplate.servicesCost +
          this.orderTemplate.additionalServicesCost -
          this.priceBreakdown.discountAmount;
        const alreadyPaid =
          this.orderTemplate.paidAmount - this.orderTemplate.refundedAmount;
        return sumToPay - alreadyPaid;
      }
      // return this.priceBreakdown.totalPrice;
    },

    async setFixedFinalPrice() {
      try {
        const dateRange = this.$refs.datePickerWrap.fp.selectedDates;
        const dateFrom = DateTime.fromJSDate(dateRange[0]);
        const dateTo = DateTime.fromJSDate(dateRange[1]);

        const payload = {
          serviceOrderId: this.orderTemplate._id,
          staffId: this.orderTemplate.staffId,
          serviceDateFrom: dateFrom.toISODate(),
          serviceDateTo: dateTo.minus({ days: 1 }).toISODate(),
          guests: this.orderTemplate.guests,
          services: this.orderTemplate.services,
          additionalServices: this.orderTemplate.additionalServices,

          _draft: {
            discounts: [],
          },
        };

        payload.services = payload.services.filter((service) => {
          return !service._isRemoved;
        });

        if (this.mode === "create") {
          payload._draft.discounts = this.orderTemplate.discounts;
        }

        const result = await api.serviceOrders.setFixedFinalPrice(
          this.orderTemplate._id,
          {
            order: payload,
          }
        );

        this.priceBreakdown = result.priceBreakdown;

        this.orderTemplate.servicesCost = result.priceBreakdown.servicesCost;
        this.orderTemplate.servicesPrepaymentAmount =
          result.priceBreakdown.servicesPrepaymentAmount;

        this.orderTemplate.totalPrice = result.priceBreakdown.totalPrice;
        this.orderTemplate.finalPrice = result.priceBreakdown.finalPrice;
        this.orderTemplate.fixedFinalPrice =
          result.priceBreakdown.fixedFinalPrice;

        this._originalOrderTemplate.servicesCost =
          result.priceBreakdown.servicesCost;
        this._originalOrderTemplate.servicesPrepaymentAmount =
          result.priceBreakdown.servicesPrepaymentAmount;

        this._originalOrderTemplate.totalPrice =
          result.priceBreakdown.totalPrice;
        this._originalOrderTemplate.finalPrice =
          result.priceBreakdown.finalPrice;
        this._originalOrderTemplate.fixedFinalPrice =
          result.priceBreakdown.fixedFinalPrice;

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        console.error(error);
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    async doPayment(operationType) {
      if (!this.doPaymentAmount || this.doPaymentAmount === "0") {
        return;
      }
      this.currentPayment = operationType;
      const payload = {
        operationType,
        amount: this.doPaymentAmount,
      };

      if (this.mode === "edit") {
        let result;

        try {
          this.isLoadingPayment = true;
          result = await api.serviceOrders.addPayment(
            this.orderTemplate._id,
            payload
          );

          this.orderTemplate.payments = result.payments;
          this.orderTemplate.paidAmount = result.paidAmount;
          this.orderTemplate.refundedAmount = result.refundedAmount;

          this.doPaymentAmount = null;

          this.$store.commit("addToast", {
            title: "Збережено",
          });
        } catch (error) {
          this.$store.commit("addToast", {
            title: "Виникла помилка. Спробуйте ще раз",
            type: "error",
          });
        } finally {
          this.isLoadingPayment = false;

          if (operationType === "monobank-acquiring" || operationType === "liqpay-acquiring") {
            await this.showInvoiceModal(this.orderTemplate._id, this.orderTemplate.clientId?.phoneNumber, result.newPayment);
          }
        }
      } else if (this.mode === "create") {
        let result;

        try {
          this.isLoadingPayment = true;

          payload._draft = this.orderTemplate.payments;

          result = await api.serviceOrders.addPaymentDraft(payload);

          this.orderTemplate.payments = result.payments;
          this.orderTemplate.paidAmount = result.paidAmount;
          this.orderTemplate.refundedAmount = result.refundedAmount;

          this.priceBreakdown.paidAmount = result.paidAmount;
          this.priceBreakdown.refundedAmount = result.refundedAmount;

          this.doPaymentAmount = null;
        } catch (error) {
          this.$store.commit("addToast", {
            title: "Виникла помилка. Спробуйте ще раз",
            type: "error",
          });
        } finally {
          this.isLoadingPayment = false;

          if (operationType === "monobank-acquiring" || operationType === "liqpay-acquiring") {
            await this.showInvoiceModal(this.orderTemplate._id, this.orderTemplate.clientId?.phoneNumber, result.newPayment);
          }
        }
      }
    },

    async removePayment(paymentId) {
      if (!paymentId) {
        return;
      }

      const payload = {
        paymentId,
      };

      try {
        if (this.mode === "edit") {
          const result = await api.serviceOrders.removePayment(
            this.orderTemplate._id,
            payload
          );

          this.orderTemplate.payments = result.payments;
          this.orderTemplate.paidAmount = result.paidAmount;
          this.orderTemplate.refundedAmount = result.refundedAmount;

          this.$store.commit("addToast", {
            title: "Збережено",
          });
        } else {
          this.orderTemplate.payments = this.orderTemplate.payments.filter(
            (payment) => {
              if (payment._id !== paymentId) {
                return true;
              }
              return false;
            }
          );
        }
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    async refundPayment(paymentId, amount) {
      if (!paymentId) {
        return;
      }

      const payload = {
        paymentId,
        amount,
      };

      try {
        if (this.mode === "edit") {
          const result = await api.serviceOrders.refundPayment(
            this.orderTemplate._id,
            payload
          );

          if (result.errorCode) {
            const title =
              result.errorMessage || "Виникла помилка. Спробуйте ще раз";

            this.$store.commit("addToast", {
              title,
              type: "error",
            });
          }

          if (!result.errorCode) {
            this.orderTemplate.payments = result.payments;
            this.orderTemplate.paidAmount = result.paidAmount;
            this.orderTemplate.refundedAmount = result.refundedAmount;

            this.$store.commit("addToast", {
              title: "Збережено",
            });
          }
        } else {
          this.orderTemplate.payments = this.orderTemplate.payments.filter(
            (payment) => {
              if (payment._id !== paymentId) {
                return true;
              }
              return false;
            }
          );
        }
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    showDiscount(discountType) {
      if (!discountType) {
        return false;
      }

      if (discountType === "absolute") {
        return true;
      }

      if (!this.orderTemplate.discounts) {
        return true;
      }

      const isFound = this.orderTemplate.discounts.some((discount) => {
        return discount.type === discountType;
      });

      return !isFound;
    },

    async doDiscount(discountType) {
      if (!this.doDiscountAmount || this.doDiscountAmount === "0") {
        return;
      }

      const payload = {
        type: discountType,
        amount: this.doDiscountAmount,
      };

      if (this.mode === "edit") {
        try {
          this.isLoadingDiscount = true;

          const result = await api.serviceOrders.addDiscount(
            this.orderTemplate._id,
            payload
          );

          this.priceBreakdown = result.priceBreakdown;

          this.orderTemplate.discounts = result.discounts;
          this.orderTemplate.totalPrice = result.priceBreakdown.totalPrice;
          this.orderTemplate.finalPrice = result.priceBreakdown.finalPrice;

          this.doDiscountAmount = null;

          this.$store.commit("addToast", {
            title: "Збережено",
          });

          await this.refreshTotalPriceAndDuration();
        } catch (error) {
          this.$store.commit("addToast", {
            title: "Виникла помилка. Спробуйте ще раз",
            type: "error",
          });
        } finally {
          this.isLoadingDiscount = false;
        }
      } else if (this.mode === "create") {
        try {
          this.isLoadingDiscount = true;

          const result = await api.serviceOrders.addDiscountDraft(payload);

          this.orderTemplate.discounts = result.discounts;

          this.doDiscountAmount = null;

          await this.refreshTotalPriceAndDuration();
        } catch (error) {
          this.$store.commit("addToast", {
            title: "Виникла помилка. Спробуйте ще раз",
            type: "error",
          });
        } finally {
          this.isLoadingDiscount = false;
        }
      }
    },

    async removeDiscount(discountId) {
      if (!discountId) {
        return;
      }

      const payload = {
        discountId,
      };

      try {
        if (this.mode === "edit") {
          const result = await api.serviceOrders.removeDiscount(
            this.orderTemplate._id,
            payload
          );

          this.priceBreakdown = result.priceBreakdown;

          this.orderTemplate.discounts = result.discounts;

          this.orderTemplate.totalPrice = result.priceBreakdown.totalPrice;
          this.orderTemplate.finalPrice = result.priceBreakdown.finalPrice;

          this.$store.commit("addToast", {
            title: "Збережено",
          });
        } else {
          this.orderTemplate.discounts = this.orderTemplate.discounts.filter(
            (discount) => {
              if (discount._id !== discountId) {
                return true;
              }
              return false;
            }
          );
        }

        await this.refreshTotalPriceAndDuration();
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    isAllowedToInvoiceSms(payment) {
      return payment.status !== "success" && payment.status !== "expired"  &&
        (payment.operationType === "monobank-acquiring" ||
        payment.operationType === "liqpay-acquiring");
    },

    async showInvoiceModal(orderId, phoneNumber, payment) {
      try {
        if (payment.liqPayInvoice) {
          this.modalParamsInvoiceSms.data.paymentId = payment.liqPayInvoice.invoiceId;
          this.modalParamsInvoiceSms.data.paymentUrl = payment.liqPayInvoice.pageUrl;
        }

        if (payment.monobankInvoice) {
          this.modalParamsInvoiceSms.data.paymentId = payment.monobankInvoice.invoiceId;
          this.modalParamsInvoiceSms.data.paymentUrl = payment.monobankInvoice.pageUrl;
        }

        this.modalParamsInvoiceSms.data.phoneNumber = phoneNumber;
        this.modalParamsInvoiceSms.data.amount = payment.amount;

        const response = await api.company.renderInvoiceMesageTemplate(
          orderId,
          payment._id,
        );

        this.modalParamsInvoiceSms.data.text = response.text;

        if (this.modalInvoiceSms) {
          this.modalInvoiceSms.show();
        }
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    closeInvoiceModal() {
      this.modalParamsInvoiceSms.data = {
        paymentId: null,
        amount: null,
        paymentUrl: null,
        text: null,
        phoneNumber: null,
      };

      this.modalInvoiceSms.hide();
    },

    async sendInvoiceInstantMessage() {
      if (!this.modalParamsInvoiceSms.data.phoneNumber) {
        this.$store.commit("addToast", {
          title: "Введіть номер телефону",
          type: "error",
        });

        return;
      }

      if (!this.modalParamsInvoiceSms.data.text) {
        this.$store.commit("addToast", {
          title: "Введіть текст повідомлення",
          type: "error",
        });

        return;
      }

      try {
        const result = await api.company.sendInstantMessage(
          this.company.smsSenderName,
          this.modalParamsInvoiceSms.data.phoneNumber,
          this.modalParamsInvoiceSms.data.text,
          "Інвойс на оплату",
        );

        if (result.status === 402) {
          this.$store.commit("addToast", {
            title: "Помилка. Поповніть баланс SMS",
            type: "error",
          });

          return;
        }

        if (result.status !== 200) {
          this.$store.commit("addToast", {
            title: "Виникла помилка. Спробуйте ще раз",
            type: "error",
          });

          return;
        }

        if (this.mode === "edit") {
          const currentUserId = JSON.parse(localStorage.localUser).userId;
          const updatedHistory = await api.serviceOrders.addHistoryUpdate(this.orderTemplate._id, {
            createdBy: currentUserId,
            subject: "SMS відправлено",
            description: `Отримувач: ${this.modalParamsInvoiceSms.data.phoneNumber}\nТекст: ${this.modalParamsInvoiceSms.data.text}`,
          });

          this.orderTemplate.history = updatedHistory.sort((a, b) => {
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            return 0;
          });
        }

        this.$store.commit("addToast", {
          title: "Відправлено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }

    },

    async copyToClipboard(value) {
      const { toClipboard } = useClipboard();
      try {
        await toClipboard(value);
        this.$store.commit("addToast", {
          title: "Скопійовано",
        });
      } catch (error) {
        console.error(error);
      }
    },

    // copyToClipboard не працював в модалці. Цей метод працює
    copyTextFromModal(text) {
      try {
        navigator.clipboard.writeText(text);
        this.$store.commit("addToast", {
          title: "Скопійовано",
        });
      } catch (error) {
        console.error(error);
      }
    }
  },
};
