<script setup>
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import { useRouteQuery } from "vue-use-route-query";
import InputApp from "@/components/common/inputs/InputApp.vue";
import { computed } from "vue";
import { useFetch } from "@/composables/common/api/useFetch";
import api from "@/services/api";
import { useRouter } from "vue-router";

const router = useRouter();
const name = useRouteQuery("namePosition", "");

const validName = computed(() => {
  return name.value.length > 2;
});

const {
  data,
  isLoading,
  fetchData: createPosition,
} = useFetch(api.positions.create, true);
async function submitForm() {
  try {
    const result = await createPosition([
      {
        name: name.value,
        isActive: true,
      },
    ]);
    if (result) {
      router.push("/beauty/onboarding/add-staff");
    }
  } catch (e) {
    console.error(e);
  }
}
</script>
<template>
  <div class="card mt-6">
    <div class="card-header">
      <div class="row">
        <div class="col-12 text-center">
          <h4>Створення посади</h4>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="submitForm" action="#">
        <div class="row text-center">
          <p>
            Додайте вашу першу посаду. <br />Це може бути "Барбер", "Старший
            перукар", "Майстер манікюру", "Тренер з Ча-ча-ча", тощо
          </p>
        </div>
        <div class="d-flex justify-content-center">
          <div class="max-width-300 w-100">
            <InputApp
              placeholder="Назва посади"
              class="w-100"
              v-model="name"
            ></InputApp>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-3">
          <ButtonApp
            type="submit"
            :loading="isLoading"
            :disabled="!validName"
            class="btn-icon bg-gradient-primary"
          >
            Створити
          </ButtonApp>
        </div>
      </form>
    </div>
  </div>
</template>
