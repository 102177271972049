<script setup>
import { computed } from "vue";
import { useCompanyStore } from "@/storePinia/company";
import { useRouteQuery } from "vue-use-route-query";

const idCreatedNumber = useRouteQuery("idRoom", "");
const store = useCompanyStore();

import config from "@/config";
const CLIENT_FORM_URL_BASE = config.CLIENT_FORM_URL_BASE;

const link = computed(() => {
  return `${CLIENT_FORM_URL_BASE}/t/${store.companyName}/room/${idCreatedNumber.value}`;
});
</script>
<template>
  <div>
    <div class="card-header">
      <div class="row">
        <div class="col-12 text-center">
          <h4>Вітаємо!</h4>
          <p>
            Ваш обʼєкт створено. Можете переглянути, як він виглядає для ваших
            гостей, за посиланням:
            <br />
            <a
              class="mt-3 d-inline-flex link-primary-"
              :href="link"
              target="_blank"
              >Переглянути</a
            >
          </p>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-12 text-center">
          <p>Щоб розпочати роботу з бронюваннями - необхідно додати ціни</p>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
          <router-link
            to="/ecommerce/rooms-prices"
            class="btn btn-icon bg-gradient-primary"
          >
            Додати ціни
          </router-link>
        </div>
        <div class="col-lg-4"></div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-4"></div>
        <div class="col-lg-4 text-center">
          <router-link
            to="/ecommerce/calendar"
            class="btn btn-outline-secondary"
          >
            Додати ціни пізніше
          </router-link>
        </div>
        <div class="col-lg-4"></div>
      </div>
    </div>
  </div>
</template>
