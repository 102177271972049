<template>
  <div class="container-fluid" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row">
      <div class="col-12">
        <router-link
          to="/team/managers/new"
          class="btn btn-primary"
          role="button"
        >
          + Адміністратор
        </router-link>

        <div class="card">
          <!-- Card header -->
          <div class="card-header">
            <h6 class="mb-0">Всі Адміністратори</h6>
            <p class="text-sm mb-0">
              Тут ви можете керувати всіма Адміністраторами, які будуть мати
              доступ до адмін панелі.
            </p>
          </div>
          <vue-good-table
            mode="remote"
            :pagination-options="paginationOptions"
            :sort-options="sortOptions"
            :totalRows="tableData.totalRecords"
            :columns="datatableColumns"
            :rows="tableData.rows"
            v-model:isLoading="isLoading"
            v-on:page-change="onPageChange"
            v-on:sort-change="onSortChange"
            v-on:column-filter="onColumnFilter"
            v-on:per-page-change="onPerPageChange"
            v-on:row-click="onRowClick"
            theme="default"
            styleClass="vgt-table"
            responsive
            compactMode
          >
            <template #table-row="props">
              <div
                v-if="props.column.field === 'correct'"
                class="text-center"
              ></div>
              <div v-if="props.column.field === 'info'">
                {{ props.row.firstName }} {{ props.row.lastName }}<br />
                <span
                  v-if="props.row?.rolesMap?.isOwner"
                  class="badge badge-sm bg-gradient-warning"
                  >Власник</span
                >
              </div>
              <div v-if="props.column.field === 'phone'">
                {{ props.row.phoneNumber }}
              </div>
              <div v-if="props.column.field === 'email'">
                {{ props.row.email }}
              </div>
              <div
                class="badge badge-dot"
                v-if="props.column.field === 'status'"
              >
                <div v-if="props.row.isActive">
                  <i class="bg-success"></i>
                  <span class="text-dark text-xs">активний</span>
                </div>
                <div v-if="!props.row.isActive">
                  <i class="bg-danger"></i>
                  <span class="text-dark text-xs">не активний</span>
                </div>
              </div>
              <div class="badge badge-dot" v-if="props.row.status === 'new'">
                <i class="bg-info"></i>
                <span class="text-dark text-xs">Нова</span>
              </div>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/views/shared/Spinner.vue";

import api from "../../../../services/api";
import { useTable } from "@/composables/good-table";

export default {
  setup() {
    const {
      onColumnFilter,
      onPageChange,
      onPerPageChange,
      onSortChange,
      loadItems,
      onRowClick,
      tableData,
      tablePerPage,
      paginationOptions,
      sortOptions,
      isLoading,
    } = useTable(api.managers.list);

    return {
      onColumnFilter,
      onPageChange,
      onPerPageChange,
      onSortChange,
      loadItems,
      onRowClick,
      paginationOptions,
      sortOptions,
      isLoading,
      tableData,
      tablePerPage,
    };
  },
  name: "settings-managers",

  components: {
    Spinner,
  },

  async mounted() {
    setTimeout(() => {
      this.dataLoaded = true;
      this.loadItems();
    }, 500);
  },

  data() {
    return {
      dataLoaded: false,
      datatableColumns: [
        {
          label: "",
          field: "correct",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Ім`я",
          field: "info",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Телефон",
          field: "phone",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "EMAIL",
          field: "email",
          sortable: false,
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ",
          tdClass: "text-xs font-weight-normal",
        },
        {
          label: "Доступність",
          field: "status",
          sortable: false,
          type: "boolean",
          thClass:
            "text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-start",
          tdClass: "text-xs font-weight-normal text-start",
        },
      ],
    };
  },

  methods: {
    async remove(id) {
      await api.managers.removeOne(id);
    },

    async toggleIsActive(manager) {
      const payload = {
        isActive: manager.isActive,
      };
      await api.managers.updateOne(manager._id, payload);
    },
  },
};
</script>
