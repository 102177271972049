<script setup>
import config from "@/config";
import { useSteps } from "@/composables/steps";
import StepList from "@/components/steps/StepList.vue";
import { computed, ref } from "vue";
import InputApp from "@/components/common/inputs/InputApp.vue";
import ButtonApp from "@/components/common/other/ButtonApp.vue";
import { mask as vMask } from "vue-the-mask";
import Multiselect from "vue-multiselect";
const API_URL_BASE = config.API_URL_BASE;

const steps = ref([
  {
    title: "Оберіть тип вашого бізнесу",
  },
  {
    title: "Оберіть кількість",
  },
  {
    title: "Як вас звати?",
  },
  {
    title: "Ваш номер телефону і email",
  },
  {
    title: "Введіть назву вашої компанії",
  },
  {
    title: "Створіть надійний пароль",
  },
  {
    title: "Токен",
  },
]);

const crmModes = [
  {
    id: 111,
    value: "hotel",
    text: "Готель",
  },
  {
    id: 112,
    value: "general",
    text: "Бьюті",
  },
];

const staffNumbers = {
  general: [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8", value: 8 },
    { text: "9", value: 9 },
    { text: "10", value: 10 },
  ],
  hotel: [
    { text: "1", value: 1 },
    { text: "2", value: 2 },
    { text: "3", value: 3 },
    { text: "4", value: 4 },
    { text: "5", value: 5 },
    { text: "6", value: 6 },
    { text: "7", value: 7 },
    { text: "8+", value: 50 },
  ],
};

const { current, nextStep, prevStep } = useSteps({
  countSteps: steps.value.length,
});

const formValues = ref({
  crmMode: "hotel",
  staffMax: { text: "1", value: 1 },
  firstName: "",
  phoneNumber: "",
  email: "",
  companyName: "",
  password: "",
  passwordRepeat: "",
  secretToken: "",
});

const isValidFirstStep = computed(() => {
  return formValues.value.crmMode;
});
const isValidSecondStep = computed(() => {
  return formValues.value.staffMax;
});
const isValidThirdStep = computed(() => {
  return formValues.value.firstName;
});
const isValidFourthStep = computed(() => {
  return formValues.value.phoneNumber && formValues.value.email;
});
const isValidFifthStep = computed(() => {
  return formValues.value.companyName;
});
const isValidSixthStep = computed(() => {
  return (
    formValues.value.password &&
    formValues.value.passwordRepeat === formValues.value.password
  );
});
const isValidSeventhStep = computed(() => {
  return formValues.value.secretToken;
});

const nextBtn = computed(() => {
  if (current.value === 1) {
    return isValidFirstStep.value;
  }
  if (current.value === 2) {
    return isValidSecondStep.value;
  }
  if (current.value === 3) {
    return isValidThirdStep.value;
  }
  if (current.value === 4) {
    return isValidFourthStep.value;
  }
  if (current.value === 5) {
    return isValidFifthStep.value;
  }
  if (current.value === 6) {
    return isValidSixthStep.value;
  }
  if (current.value === 7) {
    return isValidSeventhStep.value;
  }

  return false;
});

const prevBtn = computed(() => {
  return current.value > 1;
});

const showError = ref("");
const isLoading = ref(false);

async function signUp() {
  showError.value = "";

  const payload = {
    ...formValues.value,
  };
  delete payload.passwordRepeat;

  payload.staffMax = formValues.value.staffMax.value;

  const API_URL = `${API_URL_BASE}/authentication/signup`;
  const options = {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
  };

  try {
    isLoading.value = true;
    const response = await fetch(API_URL, options);
    const result = await response.json();
    if (result.errorCode) {
      showError.value = result.message;
      return;
    }

    const accessToken = result.accessToken;
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      // return await this.$router.push("/");

      let redirectTo = "/beauty/onboarding/welcome";
      if (payload.crmMode === "hotel") {
        redirectTo = "/hotel/onboarding/create-unit";
      }

      window.location.href = redirectTo;
    }
  } catch (error) {
    showError.value = "Внутрішня помилка. Спробуйте ще раз";
  } finally {
    isLoading.value = false;
  }
}
</script>

<template>
  <div class="card w-100 card-wrap z-index-0 fadeIn3 fadeInBottom pb-4">
    <div class="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
      <div class="bg-gradient-custom border-radius-lg py-3 pe-1">
        <h4 class="text-black font-weight-bolder text-center mb-0">
          Реєстрація
        </h4>
      </div>
      <div class="inner pt-3">
        <form action="#">
          <step-list :current="current" :steps="steps">
            <template #item_1="{ title }">
              <div>
                <h4 class="text-center mb-3">{{ title }}</h4>
                <div v-for="item of crmModes" :key="item.id">
                  <div class="form-check mb-1">
                    <input
                      class="form-check-input mx-3"
                      type="radio"
                      :name="'btnradio' + item.value"
                      :id="item.id"
                      autocomplete="off"
                      :value="item.value"
                      v-model="formValues.crmMode"
                    />
                    <label
                      class="custom-control-label"
                      :class="{ active: formValues.crmMode === item.value }"
                      :for="item.id"
                      >{{ item.text }}</label
                    >
                  </div>
                </div>
              </div>
            </template>
            <template #item_2="{ title }">
              <div>
                <h5 class="text-center mb-0">
                  {{ title }}
                  {{ formValues.crmMode == "hotel" ? "обʼєктів" : "майстрів" }}
                  <p v-if="formValues.crmMode === 'hotel'">
                    (будиночків/номерів/глемпів)
                  </p>
                </h5>

                <div class="row">
                  <div class="col-3"></div>
                  <div class="col-lg-6 d-grid gap-2 items">
                    <multiselect
                      v-model="formValues.staffMax"
                      tag-placeholder=""
                      placeholder="Оберіть кількість"
                      label="text"
                      track-by="value"
                      :select-label="''"
                      :options="staffNumbers[formValues.crmMode]"
                      :multiple="false"
                      :taggable="false"
                    >
                    </multiselect>
                  </div>
                  <div class="col-3"></div>
                </div>
              </div>
            </template>
            <template #item_3="{ title }">
              <div>
                <h4 class="text-center mb-3">{{ title }}</h4>
                <div class="d-grid gap-2 items">
                  <InputApp v-model="formValues.firstName" placeholder="Ім'я" />
                </div>
              </div>
            </template>
            <template #item_4="{ title }">
              <div>
                <h4 class="text-center mb-3">{{ title }}</h4>
                <div class="row">
                  <div class="col-3"></div>
                  <div class="col-lg-6">
                    <div class="d-grid gap-2 items">
                      <InputApp
                        v-model="formValues.phoneNumber"
                        v-mask="'+380#########'"
                        class="form-control"
                        maxlength="13"
                        minlength="13"
                        pattern="\+[0-9]{12}"
                        placeholder="+380"
                        type="tel"
                      />
                      <InputApp
                        v-model="formValues.email"
                        placeholder="Email"
                        type="email"
                      />
                    </div>
                  </div>
                  <div class="col-3"></div>
                </div>
              </div>
            </template>
            <template #item_5="{ title }">
              <div>
                <h4 class="text-center mb-3">{{ title }}</h4>
                <div class="d-grid gap-2 items">
                  <InputApp
                    v-model="formValues.companyName"
                    class="form-control"
                    placeholder="Назва компанії"
                  />
                </div>
              </div>
            </template>
            <template #item_6="{ title }">
              <div>
                <h4 class="text-center mb-2">{{ title }}</h4>
                <!-- <p class="text-center">Введіть пароль і повторіть його</p> -->
                <div class="d-grid gap-2 items">
                  <InputApp
                    v-model="formValues.password"
                    class="form-control"
                    placeholder="Пароль"
                    type="password"
                  />
                  <InputApp
                    v-model="formValues.passwordRepeat"
                    class="form-control"
                    placeholder="Повторіть пароль"
                    type="password"
                  />
                </div>
              </div>
            </template>
            <template #item_7="{ title }">
              <div>
                <h4 class="text-center mb-2">{{ title }}</h4>
                <div class="d-grid gap-2 items">
                  <InputApp
                    v-model="formValues.secretToken"
                    class="form-control"
                    placeholder="Secret token"
                    type="email"
                  />
                </div>
              </div>
            </template>
          </step-list>
          <div
            v-if="showError"
            class="alert alert-danger alert-dismissible text-white mt-3"
            role="alert"
          >
            <span class="text-sm">{{ showError }}</span>
          </div>
        </form>
        <div class="d-flex gap-1 items-center mt-3 justify-content-between">
          <ButtonApp
            :disabled="!prevBtn"
            class="btn-outline-primary"
            @click="prevStep"
          >
            Назад
          </ButtonApp>
          <ButtonApp
            v-if="current !== steps.length"
            :disabled="!nextBtn"
            class="btn-primary"
            @click="nextStep"
            >Далі
          </ButtonApp>
          <ButtonApp
            v-else
            :disabled="!nextBtn"
            :loading="isLoading"
            class="btn-primary"
            @click="signUp"
            >Зареєструватись
          </ButtonApp>
        </div>
        <div class="text-center">
          <p class="mt-4 text-sm text-center">
            Є аккаунт?
            <router-link
              class="text-primary text-gradient font-weight-bold"
              to="/authentication/signin"
            >
              Увійти
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-wrap {
  max-width: 700px;
  margin: 0 auto;
}
.inner {
  max-width: 400px;
  margin: 0 auto;
}
:deep(.steps) {
  max-width: 200px;
}
.bg-gradient-custom {
  background-color: #ffbb0d;
}
</style>
