<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mb-5">
      <div class="col-lg-12 mt-lg-0">
        <div class="row">
          <div class="available">
            <div class="element">Доступність</div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="service.isActive"
              />
            </div>
          </div>
        </div>
        <div class="card" id="basic-info">
          <div class="card-header">
            <div class="row">
              <div class="col-9">
                <h6>Додаткова послуга</h6>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-lg-4">
                <label class="text-md">Назва</label>
                <div class="input-group input-group-outline">
                  <input
                    type="text"
                    class="form-control"
                    v-model="service.name"
                    :disabled="prohibitChangingServiceName()"
                  />
                </div>
              </div>
              <div class="col-lg-2">
                <label class="text-md">Ціна (грн)</label>
                <div class="input-group input-group-outline">
                  <input
                    type="tel"
                    class="form-control"
                    v-model="service.price"
                  />
                </div>
              </div>
              <div
                class="col-lg-2"
                v-if="
                  branch.paymentLiqPayEnabled || branch.paymentMonobankEnabled
                "
              >
                <label class="text-md">Сума передплати (грн)</label>
                <div class="input-group input-group-outline">
                  <input
                    type="tel"
                    class="form-control"
                    v-model="service.prepaymentAmount"
                  />
                </div>
              </div>
              <div class="col-lg-4" v-if="optionsSelect?.length">
                <label class="text-md" v-if="company.crmMode !== 'hotel'"
                  >Оберіть майстрів</label
                >
                <label class="text-md" v-if="company.crmMode === 'hotel'"
                  >Оберіть номери</label
                ><br />
                <SelectApp
                  v-model="selectedStaffs"
                  :settings="{
                    options: optionsSelect,
                    trackBy: 'code',
                    label: 'name',
                    placeholder: 'Оберіть номери',
                    closeOnSelect: false,
                    taggable: true,
                    multiple: true,
                  }"
                  @select="onMultiselectSelect"
                  @remove="onMultiselectSelect"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-4">
                <label class="text-md">Опис послуги</label>
                <div class="input-group input-group-outline">
                  <textarea
                    class="form-control"
                    style="height: 75px"
                    v-model="service.description"
                    ref="description"
                    rows="2"
                    @input="autoResizeTextarea"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-4 mt-2" v-if="company.crmMode === 'hotel'">
                <label class="text-md">Додаткові правила</label>
                <div
                  class="form-check customCheckbox"
                  v-for="(v, k, i) in customRules"
                  v-bind:key="i"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="service[k]"
                    id="fcustomCheck1"
                  />
                  <label
                    class="custom-control-label customLabelCheckbox"
                    for="customCheck1"
                    >{{ v.description }}
                  </label>
                  <a
                    v-if="v.tooltip && v.tooltip.length"
                    href="javascript:"
                    class="mx-1"
                    data-bs-toggle="tooltip"
                    :data-bs-original-title="v.tooltip"
                  >
                    <i
                      class="material-icons text-secondary position-relative text-lg"
                      >info</i
                    >
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Видалення -->
        <div class="card mt-4" id="delete" v-if="showDeleteBlock()">
          <div class="badge bg-gradient-danger">DANGER ZONE</div>
          <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div class="w-50">
                <h6>Видалити додаткову послугу</h6>
              </div>
              <div class="w-50 text-end">
                <button
                  class="btn bg-gradient-danger mb-0 ms-2"
                  type="button"
                  name="button"
                  data-bs-toggle="modal"
                  :data-bs-target="`#${deleteServiceModalParameters.modalId}`"
                  :disabled="
                    service.name === 'Ранній заїзд' ||
                    service.name === 'Пізній виїзд'
                  "
                >
                  Видалити
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Видалення finish -->

        <!--  Зберегти -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                  :disabled="!preSaveValidation()"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти finish-->
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="deleteServiceModalParameters"></BmnCustomPopup>
</template>
<style scoped>
.customLabelCheckbox {
  padding-left: 5px;
}
.customCheckbox {
  padding-left: 0px;
}
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.available {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-end;
}
.element {
  margin-right: 10px;
}
</style>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import api from "@/services/api";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";

import { useFetch } from "@/composables/common/api/useFetch";
import Spinner from "@/views/shared/Spinner.vue";
import SelectApp from "@/components/common/SelectApp.vue";
import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";

const { data: dataResources, fetchData: getResourcesApi } = useFetch(
  api.staff.list
);

async function getResources() {
  const params = {
    isActive: "1",
  };
  await getResourcesApi([params]);
  dataResources.value = dataResources.value.map((item) => {
    return {
      id: item._id,
      title: item.name,
      rank: item.rank,
    };
  });

  return dataResources.value;
}

export default {
  async beforeMount() {
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");
    this.company = await api.company.show();
    this.branch = (await api.branches.list())[0];

    this.staffs = await getResources();
    this.optionsSelect = this.staffs.map((item) => ({
      name: item.title,
      code: item.id,
    }));

    const optionSelectAll = {
      name: "Всі",
      code: 0,
      $isDisabled: true,
    };

    this.optionsSelect.unshift(optionSelectAll);

    if (this.$route.params.id) {
      this.mode = "edit";
      this.service = await api.additionalServices.show(this.$route.params.id);

      if (this.service.name === "Ранній заїзд") {
        this.customRules = {
          isAvailableNoPreviousDayBookings: {
            description: "Доступна в разі вільної попередньої доби",
            tooltip:
              "Бронюючи онлайн, ваш Гість буде бачити цю додаткову послугу лише якщо попередня до його заїзду доба - вільна. Наприклад, це зручно для раннього заїзду.",
          },
          prohibitPreviousDayBooking: {
            description: "Закрити продаж попереднього дня",
            tooltip:
              "Якщо ваш Гість обере цю додаткову послугу, то доба перед заїздом буде автоматично закрита для бронювання інших гостів.",
          },
        };
      }

      if (this.service.name === "Пізній виїзд") {
        this.customRules = {
          isAvailableNoNextDayBookings: {
            description: "Доступна в разі вільної наступної доби",
            tooltip:
              "Бронюючи онлайн, ваш Гість буде бачити цю додаткову послугу лише якщо наступна після виселення доба - вільна. Наприклад, це зручно для пізнього виїзду.",
          },
          prohibitNextDayBooking: {
            description: "Закрити продаж наступного дня",
            tooltip:
              "Якщо ваш Гість обере цю додаткову послугу, то доба після дати виїзду Гостя буде автоматично закрита від бронювання іншими гостями.",
          },
        };
      }

      this.selectedStaffs = this.staffs
        .filter((s) => {
          for (const staff of this.service.staffIds) {
            if (staff._id === s.id) {
              return true;
            }
          }

          return false;
        })
        .map((s) => {
          return {
            name: s.title,
            code: s.id,
          };
        });

      if (this.selectedStaffs.length === 0) {
        this.selectedStaffs.push(optionSelectAll);
      }
    } else {
      this.selectedStaffs.push(optionSelectAll);
    }

    this.dataLoaded = true;
  },

  components: {
    SelectApp,
    BmnCustomPopup,
    Spinner,
  },

  async mounted() {
    setTimeout(autoResizeTextarea, 1000, {
      target: this.$refs.description,
    });
    setTooltip();
  },

  data() {
    return {
      showDevFeatures: false,
      dataLoaded: false,
      mode: "create", // create | edit

      company: {
        crmMode: "",
      },
      branch: {
        paymentMonobankEnabled: false,
        paymentLiqPayEnabled: false,
      },

      customRules: {
        isPricePerDay: {
          description: "Ціна подобова",
        },
        isPricePerGuest: {
          description: "Ціна за кожного гостя",
        },
        isPreselected: {
          description: "Обрана за замовчуванням",
        },
      },

      positions: [],
      staffs: [],
      optionsSelect: [],
      selectedStaffs: [],
      service: {
        name: "",
        description: "",
        price: 0,
        prepaymentAmount: 0,
        isPricePerDay: false,
        isPricePerGuest: false,
        isPreselected: false,
        isActive: true,
        staffIds: [],
      },
      deleteServiceModalParameters: {
        modalId: "modal-delete-1",
        modalTitle: "Видалення додаткової послуги",
        modalDescription: "Ви впевнені, що хочете видалити додаткову послугу?",
        modalButtonConfirmText: "Видалити",
        modalButtonCancelText: "Відмінити",
        confirmHandler: async () => {
          await api.additionalServices.delete(this.service._id);
          this.$store.commit("addToast", {
            title: "Видалено",
          });
          await this.$router.push("/settings/additional-services");
        },
      },
    };
  },

  methods: {
    autoResizeTextarea,
    async save() {
      try {
        this.service.staffIds = this.selectedStaffs
          .map((s) => {
            return s.code;
          })
          .filter((code) => {
            return code !== 0;
          });

        if (this.mode === "create") {
          await api.additionalServices.create(this.service);
        } else if (this.mode === "edit") {
          await api.additionalServices.update(
            this.$route.params.id,
            this.service
          );
        }

        this.$store.commit("addToast", {
          title: "Збережено",
        });

        await this.$router.push("/settings/additional-services");
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    prohibitChangingServiceName() {
      return this.mode === "edit" && this.service.isSystem;
    },

    preSaveValidation() {
      return this.service.name;
    },

    onMultiselectSelect(selectedOrRemovedOption, id) {
      if (this.selectedStaffs.length === 1) {
        return;
      }

      if (this.selectedStaffs.length === 0) {
        this.selectedStaffs.push(this.optionsSelect[0]);
        return;
      }

      const optionAll = this.selectedStaffs.find((option) => {
        return option.code === 0;
      });

      if (optionAll) {
        this.selectedStaffs = this.selectedStaffs.filter((option) => {
          return option.code !== 0;
        });
      }
    },
    showDeleteBlock() {
      return this.mode === "edit" && !this.service.isSystem;
    },
  },
};
</script>
