<template>
  <div class="container-fluid" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="card" v-if="company.crmMode === 'hotel'">
      <!-- Card header -->
      <div class="card-body">
        <h6 class="mb-0">Системні додаткові послуги</h6>
      </div>
      <div class="vgt-responsive">
        <table class="custom-table vgt-table">
          <thead>
            <tr>
              <th
                scope="col"
                class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 thead th"
                aria-controls="col-1"
                style="width: 50px"
              ></th>
              <th
                scope="col"
                class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 thead th"
                aria-controls="col-1"
                style="min-width: auto; width: auto"
              >
                <span>Назва</span>
              </th>
              <th
                scope="col"
                class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                aria-controls="col-1"
                style="min-width: auto; width: auto"
              >
                <span>Номери</span>
              </th>
              <th
                scope="col"
                class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                aria-controls="col-1"
                style="min-width: auto; width: auto"
              >
                <span>Ціна (грн)</span>
              </th>
              <th
                scope="col"
                class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                aria-controls="col-1"
                style="min-width: auto; width: auto"
              >
                <span>Передоплата (грн)</span>
              </th>
              <th
                scope="col"
                class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                aria-controls="col-1"
                style="min-width: auto; width: auto"
              >
                <span>Доступність</span>
              </th>
              <th
                scope="col"
                class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                aria-controls="col-1"
                style="min-width: auto; width: auto"
              ></th>
            </tr>
          </thead>
          <draggable
            v-model="tableData.rows"
            tag="tbody"
            item-key="name"
            handle=".handle"
            id="additional-service-draggable-table"
          >
            <template #item="{ element }">
              <tr v-if="element.isSystem">
                <td>
                  <div class="text-center disableCopyElement"></div>
                </td>
                <td
                  class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                >
                  {{ element.name }}
                </td>
                <td
                  class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                >
                  <div
                    v-for="(staff, idx) in element.staffIds"
                    v-bind:key="idx"
                  >
                    {{ staff.name }}
                  </div>
                  <div v-if="!element.staffIds || !element.staffIds.length">
                    Всі
                  </div>
                </td>
                <td
                  class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                >
                  {{ element.price }}
                </td>
                <td
                  class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                >
                  {{ element.prepaymentAmount }}
                </td>
                <td>
                  <div class="mx-1">
                    <div class="badge badge-dot">
                      <i
                        :class="[element.isActive ? 'bg-success' : 'bg-danger']"
                      ></i>
                      <span class="text-dark text-xs"
                        >{{ element.isActive ? "" : "не " }}активно</span
                      >
                    </div>
                  </div>
                </td>
                <td>
                  <span
                    class="material-icons align-middle mb-1"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Редагувати послугу"
                    @click="routeToAdditionalService(element._id)"
                  >
                    edit
                  </span>
                </td>
              </tr>
            </template>
          </draggable>
        </table>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="row mt-4">
          <div class="col">
            <router-link
              class="btn btn-primary"
              to="/settings/additional-services/new"
              role="button"
              >+ додаткова послуга
            </router-link>
          </div>
        </div>
        <div class="card mb-2">
          <!-- Card header -->
          <div class="card-body">
            <h6 class="mb-0">Ваші додаткові послуги</h6>
            <p class="text-sm mb-0" v-if="company.crmMode === 'hotel'">
              Створюйте додаткові послуги, які ви можете додавати до бронювань.
            </p>
            <p class="text-sm mb-0" v-if="company.crmMode !== 'hotel'">
              Створюйте додаткові послуги, які ви можете додавати до записів.
            </p>
          </div>

          <div class="vgt-responsive">
            <table class="custom-table vgt-table">
              <thead>
                <tr>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 thead th"
                    aria-controls="col-1"
                    style="width: 50px"
                  ></th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 thead th"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>Назва</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span v-if="company.crmMode === 'hotel'">Номери</span>
                    <span v-if="company.crmMode !== 'hotel'">Майстри</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>Ціна (грн)</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>Передоплата (грн)</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                    v-if="company.crmMode === 'hotel'"
                  >
                    <span>Подобова</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                    v-if="company.crmMode === 'hotel'"
                  >
                    <span>Ціна за гостя</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                    v-if="company.crmMode === 'hotel'"
                  >
                    <span>Обрана за замовчуванням</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  >
                    <span>Доступність</span>
                  </th>
                  <th
                    scope="col"
                    class="vgt-left-align text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    aria-controls="col-1"
                    style="min-width: auto; width: auto"
                  ></th>
                </tr>
              </thead>

              <draggable
                @end="onEnd"
                v-model="tableData.rows"
                v-bind="dragOptions"
                tag="tbody"
                item-key="name"
                handle=".handle"
                id="additional-service-draggable-table"
              >
                <template #item="{ element }">
                  <tr v-if="!element.isSystem">
                    <td>
                      <div class="text-center disableCopyElement">
                        <i class="material-icons text-lg me-2 grab-box handle"
                          >drag_indicator</i
                        >
                      </div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      {{ element.name }}
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      <div
                        v-for="(staff, idx) in element.staffIds"
                        v-bind:key="idx"
                      >
                        {{ staff.name }}
                      </div>
                      <div v-if="!element.staffIds || !element.staffIds.length">
                        Всі
                      </div>
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      {{ element.price }}
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                    >
                      {{ element.prepaymentAmount }}
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                      v-if="company.crmMode === 'hotel'"
                    >
                      {{ element.isPricePerDay ? "так" : "ні" }}
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                      v-if="company.crmMode === 'hotel'"
                    >
                      {{ element.isPricePerGuest ? "так" : "ні" }}
                    </td>
                    <td
                      class="vgt-left-align text-xs font-weight-normal disableCopyElement"
                      v-if="company.crmMode === 'hotel'"
                    >
                      {{ element.isPreselected ? "так" : "ні" }}
                    </td>
                    <td>
                      <div class="mx-1">
                        <div class="badge badge-dot">
                          <i
                            :class="[
                              element.isActive ? 'bg-success' : 'bg-danger',
                            ]"
                          ></i>
                          <span class="text-dark text-xs"
                            >{{ element.isActive ? "" : "не " }}активно</span
                          >
                        </div>
                      </div>
                    </td>
                    <td>
                      <span
                        class="material-icons align-middle mb-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Редагувати послугу"
                        @click="routeToAdditionalService(element._id)"
                      >
                        edit
                      </span>
                    </td>
                  </tr>
                </template>
              </draggable>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/views/shared/Spinner.vue";

import api from "@/services/api";
import { useTable } from "@/composables/good-table";
import draggable from "vuedraggable";

export default {
  setup() {
    const { loadItems, tableData, isLoading } = useTable(
      api.additionalServices.list
    );

    return {
      loadItems,
      isLoading,
      tableData,
    };
  },
  name: "settings-services",

  data() {
    return {
      dataLoaded: false,
      company: {
        crmMode: "",
      },
    };
  },

  components: {
    draggable,
    Spinner,
  },

  async mounted() {
    this.company = await api.company.show();

    setTimeout(async () => {
      await this.loadItems();
      this.dataLoaded = true;
    }, 500);
  },

  computed: {
    dragOptions() {
      return {
        animation: 500,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    routeToAdditionalService(id) {
      this.$router.push(`/settings/additional-services/${id}`);
    },

    async onEnd() {
      let sortIndex = 0;
      const newOrder = this.tableData.rows.map((item) => {
        sortIndex += 1;
        return {
          _id: item._id,
          rank: sortIndex,
        };
      });

      try {
        await api.additionalServices.sort(newOrder);
        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.disableCopyElement {
  -webkit-touch-callout: none; /* Отключение контекстного меню iOS */
  -webkit-user-select: none; /* Отключение выбора текста в Safari */
  -khtml-user-select: none; /* Отключение выбора текста в Konqueror HTML */
  -moz-user-select: none; /* Отключение выбора текста в Firefox */
  -ms-user-select: none; /* Отключение выбора текста в Internet Explorer/Edge */
  user-select: none; /* Отключение выбора текста в большинстве современных браузеров */
}

table.vgt-table td {
  vertical-align: middle;
}

.img-thumbnail {
  padding: 0;
  border: none;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

tr {
  background-color: #fff;
  cursor: pointer;

  &:hover {
    background: #fbfbfb;
  }
}

.grab-box {
  cursor: grab;
}

.ghost {
  opacity: 0.7;
  background: #d7d6d6;
}
</style>
